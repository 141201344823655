import React from "react";
import "../BookmarkGroup/GroupBookmarks.scss";
import ModalBookmarkOtherAdd from "./ModalBookmarkOtherAdd";
import BookmarkBox from "../BookmarkBox/BookmarkBox";
import {ScrollBlock} from "../../../../../components/ScrollBlock/ScrollBlock";

export const BookmarkGroupOtherView = (props) => {
    const {
        bookmarkGroupData,

        bookmarkGroupBlock,
        callBackRef,
        pageHeight,
        showBlock,
        boxTop,
        boxWidth,
        boxLeft,

        isModalBookmarkAdd,
        setIsModalBookmarkAdd,

        upBookmarksGroup,
    } = props

    return (
        <React.Fragment>
            {bookmarkGroupData && (
              <ScrollBlock
                style={{
                    width: "100%",
                    height: "100%",
                }}
              >
                <div className="GroupBookmarks__section">

                    <div className="Section__header">
                        <div className="Header__wrapper">
                            <div className="Header__title">
                                <div className="Title__text">
                                    Разное
                                </div>
                            </div>
                            <div className="Header__menu">
                                {bookmarkGroupData.bookmarks && bookmarkGroupData.bookmarks.length > 0 && (
                                    <div className="Menu__box-count">
                                        <div className="Box-count__icon icon__bookmark icon__bookmark_rotate"></div>
                                        <div className="Box-count__text">
                                            {bookmarkGroupData.bookmarks.length}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {!!bookmarkGroupData.bookmarks && bookmarkGroupData.bookmarks.length > 0 && (
                    <div ref={callBackRef}
                    className="Section__content"
                    style={{
                        position: 'relative',
                        height: bookmarkGroupBlock
                        ? pageHeight(bookmarkGroupBlock)
                        : 0,
                    }}
                    >

                        {bookmarkGroupData.bookmarks.map((bookmark, index) =>
                        bookmarkGroupBlock &&
                        showBlock(bookmarkGroupBlock, index) && (
                            <BookmarkBox
                                top={
                                    bookmarkGroupBlock
                                    ? boxTop(bookmarkGroupBlock, index)
                                    : 0
                                }
                                left={
                                    bookmarkGroupBlock
                                    ? boxLeft(bookmarkGroupBlock, index)
                                    : 0
                                }
                                width={
                                    bookmarkGroupBlock
                                    ? boxWidth(bookmarkGroupBlock)
                                    : 270 - 24
                                }

                                bookmarkGroupBlock={bookmarkGroupBlock}
                                bookmarkData={bookmark}
                                key={'bookmark_' + bookmark.id}/>
                        ))}
                    </div>)}


                    {/*<div*/}
                    {/*    className="LinksGroup__button-up"*/}
                    {/*    onClick={() => upBookmarksGroup()}>*/}
                    {/*    <div className="button"/>*/}
                    {/*</div>*/}

                    <div
                        className="LinksGroup__button-add"
                        onClick={() => setIsModalBookmarkAdd(true)}>
                        <div
                            className="button"/>
                    </div>
                </div>
              </ScrollBlock>
            )}

            {!!isModalBookmarkAdd && (
                <ModalBookmarkOtherAdd
                  handleClose={() => setIsModalBookmarkAdd(false)}
                />
            )}

        </React.Fragment>
    );
}
