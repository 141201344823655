import React from "react";
import "./GroupBookmarks.scss";
import ModalBookmarksGroupDelete from "./ModalBookmarksGroupDelete";
import ModalBookmarksGroupEdit from "./ModalBookmarksGroupEdit";
import ModalBookmarkAdd from "./ModalBookmarkAdd";
import BookmarkBox from "../BookmarkBox/BookmarkBox";
import {ScrollBlock} from "../../../../../components/ScrollBlock/ScrollBlock";

export const BookmarkGroupView = (props) => {
  const {
    bookmarkGroupData,

    bookmarkGroupBlock,
    callBackRef,
    pageHeight,
    showBlock,
    boxTop,
    boxWidth,
    boxLeft,

    isModalBookmarkAdd,
    setIsModalBookmarkAdd,

    upBookmarksGroup,

    isModalBookmarksGroupEdit,
    setIsModalBookmarksGroupEdit,

    isModalBookmarksGroupDelete,
    setIsModalBookmarksGroupDelete,

    componentVisibleRef,
    isMenuSettingsPopup,
    setIsMenuSettingsPopup,
  } = props

  return (
        <React.Fragment>
            {bookmarkGroupData && (
              <ScrollBlock
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <div className="GroupBookmarks__section">







                    <div className="Section__header">
                        <div className="Header__wrapper">
                            <div className="Header__title">
                                {/* <div className="Title__marker">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <rect width="24" height="24" rx="12" fill="#7ca246"/>
                                    </svg>
                                </div> */}
                                <div className="Title__text">
                                    {bookmarkGroupData.title}
                                </div>
                            </div>
                            <div className="Header__menu">
                                {bookmarkGroupData.bookmarks && bookmarkGroupData.bookmarks.length > 0 && (
                                    <div className="Menu__box-count">
                                        <div className="Box-count__icon icon__bookmark icon__bookmark_rotate"></div>
                                        <div className="Box-count__text">
                                            {bookmarkGroupData.bookmarks.length}
                                        </div>
                                    </div>
                                )}
                                {/* <div className="Menu__box-count">
                                    <div className="Box-count__icon icon__star"></div>
                                    <div className="Box-count__text">
                                        124
                                    </div>
                                </div> */}

                                {/* <div className="Menu__link"></div> */}
                                <div className="Menu__popup" ref={componentVisibleRef}>
                                    <div className={(isMenuSettingsPopup ? 'Menu__settings active' : 'Menu__settings')}
                                        onClick={() => setIsMenuSettingsPopup(!isMenuSettingsPopup)}>
                                    </div>
                                    <div className="Popup__menu" style={{
                                            pointerEvents: (isMenuSettingsPopup ? 'auto' : 'none'),
                                            opacity: (isMenuSettingsPopup ? 1 : 0),
                                            top: (isMenuSettingsPopup ? 32 + 6 : 32),
                                        }}>
                                        <div className="Popup__button" onClick={() => (setIsModalBookmarksGroupEdit(true), setIsMenuSettingsPopup(!isMenuSettingsPopup))}>
                                            <span className="Popup__text">Редактировать</span>
                                            {/* <div className="Popup__icon icon-edit"></div> */}
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                                <g transform="translate(-5 -5)">
                                                    <rect width="32" height="32" transform="translate(5 5)" fill="#fff" opacity="0"/>
                                                    <g transform="translate(9 8.999)">
                                                        <path d="M2.657,17.5a2.447,2.447,0,0,1,.707-1.509L13.983,5.367l1.73,1.73L6.2,16.612l1.73,1.73,9.515-9.515,1.73,1.73L9.658,20.073l1.73,1.73L20.9,12.287l1.73,1.73L12.014,24.636a2.447,2.447,0,0,1-1.509.707L3.334,26a1.223,1.223,0,0,1-1.329-1.329Zm6.478,5.516-3.656.332A1.4,1.4,0,0,0,5.1,22.9c-.31-.272-.454-.272-.454-.272l.342-3.762Z" transform="translate(-2 -1.999)" fill="#fff" fillRule="evenodd"/>
                                                        <path d="M13.054,4.447l1.731-1.731a2.447,2.447,0,0,1,3.461,0l5.192,5.192a2.447,2.447,0,0,1,0,3.461L21.707,13.1Z" transform="translate(-0.148 -1.999)" fill="#fff"/>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <div className="Popup__hr"></div>
                                        <div className="Popup__button" onClick={() => (setIsModalBookmarksGroupDelete(true), setIsMenuSettingsPopup(!isMenuSettingsPopup))}>
                                            <span className="Popup__text">Удалить</span>
                                            {/* <div className="Popup__icon icon-delete"></div> */}
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                                <g transform="translate(32) rotate(90)">
                                                    <rect width="32" height="32" fill="none"/>
                                                    <rect width="24" height="24" rx="12" transform="translate(4 4)" fill="#de1717"/>
                                                    <rect width="4" height="12" rx="1" transform="translate(14 10)" fill="#fff"/>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>







                    {bookmarkGroupData.bookmarks && bookmarkGroupData.bookmarks.length > 0 && (
                    <div
                    ref={callBackRef}
                     className="Section__content"
                     style={{
                        position: 'relative',
                        height: bookmarkGroupBlock
                        ? pageHeight(bookmarkGroupBlock)
                        : 0,
                    }}
                     >
                        {bookmarkGroupData.bookmarks.map((bookmark, index) =>
                            bookmarkGroupBlock &&
                        showBlock(bookmarkGroupBlock, index) && (
                            <BookmarkBox
                                top={
                                    bookmarkGroupBlock
                                    ? boxTop(bookmarkGroupBlock, index)
                                    : 0
                                }
                                left={
                                    bookmarkGroupBlock
                                    ? boxLeft(bookmarkGroupBlock, index)
                                    : 0
                                }
                                width={
                                    bookmarkGroupBlock
                                    ? boxWidth(bookmarkGroupBlock)
                                    : 270 - 24
                                }
                                bookmarkGroupBlock={bookmarkGroupBlock}
                                bookmarkData={bookmark}
                                key={'bookmark_' + bookmark.id}/>
                        ))}

                    </div>
                    )}

                    {/*<div*/}
                    {/*    className="LinksGroup__button-up"*/}
                    {/*    onClick={() => upBookmarksGroup()}>*/}
                    {/*    <div className="button"/>*/}
                    {/*</div>*/}

                    <div
                        className="LinksGroup__button-add"
                        onClick={() => setIsModalBookmarkAdd(true)}>
                        <div
                            className="button"/>
                    </div>

                </div>
              </ScrollBlock>
            )}

            {!!isModalBookmarkAdd && (
                <ModalBookmarkAdd
                    handleClose={() => setIsModalBookmarkAdd(false)}
                    bookmarkGroupData={bookmarkGroupData}/>
            )}

            {!!isModalBookmarksGroupEdit && (
                <ModalBookmarksGroupEdit
                    handleClose={() => setIsModalBookmarksGroupEdit(false)}
                    bookmarkGroupData={bookmarkGroupData}
                />
            )}

            {!!isModalBookmarksGroupDelete && (
                <ModalBookmarksGroupDelete
                    handleClose={() => setIsModalBookmarksGroupDelete(false)}
                    bookmarkGroupData={bookmarkGroupData}/>
            )}

        </React.Fragment>
    );
}
