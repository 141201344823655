'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PreloadImage = function (_React$Component) {
  _inherits(PreloadImage, _React$Component);

  function PreloadImage(props) {
    _classCallCheck(this, PreloadImage);

    var _this = _possibleConstructorReturn(this, _React$Component.call(this, props));

    _this.state = {
      loaded: false,
      src: null
    };
    return _this;
  }

  PreloadImage.prototype.componentDidMount = function componentDidMount() {
    if (this.props.lazy && 'IntersectionObserver' in window) {
      this.setObserver();
    } else {
      this.setPreloader();
    }
  };

  PreloadImage.prototype.setObserver = function setObserver() {
    var _this2 = this;

    this.observer = new IntersectionObserver(function (entries) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          _this2.setPreloader();
          _this2.observer.disconnect();
        }
      });
    });

    this.observer.observe(this.el);
  };

  PreloadImage.prototype.setPreloader = function setPreloader() {
    var _this3 = this;

    this.preloader = new Image();

    this.preloader.onload = function () {
      return _this3.setState({
        loaded: true,
        src: 'url(' + _this3.props.src + ')'
      });
    };

    this.preloader.src = this.props.src;
  };

  PreloadImage.prototype.componentWillUnmount = function componentWillUnmount() {
    if (this.observer) this.observer.disconnect();
    if (this.preloader) this.preloader.onload = null;
  };

  PreloadImage.prototype.render = function render() {
    var _this4 = this;

    var backgroundSize = this.props.innerStyle && this.props.innerStyle.backgroundSize ? this.props.innerStyle.backgroundSize : "cover";
    var backgroundPosition = this.props.innerStyle && this.props.innerStyle.backgroundPosition ? this.props.innerStyle.backgroundPosition : "center";
    var backgroundRepeat = this.props.innerStyle && this.props.innerStyle.backgroundRepeat ? this.props.innerStyle.backgroundRepeat : "no-repeat";

    return _react2.default.createElement(
      'div',
      {
        // Required: Relative, absolute, or fixed position
        // Required: Width & height (explicitly or via top/right/bottom/left)
        // Optional: Background color or placeholder image
        className: this.props.className,
        style: _extends({}, this.props.style),
        ref: function ref(el) {
          return _this4.el = el;
        }
      },
      _react2.default.createElement('div', { style: {
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundImage: this.state.src,
          backgroundSize: backgroundSize,
          backgroundPosition: backgroundPosition,
          backgroundRepeat: backgroundRepeat,
          transition: 'opacity ' + (this.props.duration || '300ms') + ' ' + (this.props.ease || 'cubic-bezier(0.215, 0.61, 0.355, 1)'),
          opacity: this.state.loaded ? 1 : 0
        } }),
      this.props.children
    );
  };

  return PreloadImage;
}(_react2.default.Component);

exports.default = PreloadImage;
module.exports = exports['default'];