import React, {useContext, useEffect, useState} from "react";
import PreloadImage from '../../../../../components/PreloadImage/PreloadImage';
import "./BookmarkBox.scss";
import {
    BookmarksContext, 
    saveBookmarksGroup, 
    saveLinksDeleted, 
    bookmarkDraggable,
    bookmarkModal,
} from "../../../../../contexts/bookmarks";
import axios from "axios";
import {ROOT_URL} from "../../../../../config/url";
import {
    faviconUrl,
    linkTitle,
    hostName,
    searchImageMetaTags,
    dndArray,
    deletedAtDash,
} from "../../../../../functions/inbookmarkLibrary";

const BookmarkBox = React.memo(
function BookmarkBox(props) {
    // const {notificationState, notificationDispatch} = useContext(NotificationContext);
    const {bookmarksState, bookmarksDispatch} = useContext(BookmarksContext);
    const [bookmarkData, setBookmarkData] = useState(props.bookmarkData);
    const [draggableBookmark, setDraggableBookmark] = useState(bookmarksState.draggableBookmark);
    const [isShowDraggableBookmark, setIsShowDraggableBookmark] = useState(bookmarksState.isShowDraggableBookmark);
    const [isHover, setIsHover] = useState(false);
    // const [bookmarkBlock, setBookmarkBlock] = useState(null);

    /* Отлючение каналов для тестов производительности */
    async function axiosBookmark(bookmarksGroupId, bookmarkId, callbackSuccess, callbackError) {
        await axios.get(
            '/api/links/group/' + bookmarksGroupId + '/link/' + bookmarkId,
            {
                withCredentials: false,
                baseURL: ROOT_URL,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + localStorage.getItem('tokenSession')
                }
            }).then((response) => {
            if (callbackSuccess) callbackSuccess(response);
        }).catch((err) => {
            if (callbackError) callbackError(err);
        });
    }

    // useEffect(() => {
    //     /* Подключение вебсокета */
    //
    //     // window.Echo = new Echo({
    //     //     broadcaster: 'socket.io',
    //     //     // host: window.location.hostname + ":6001",
    //     //     host: ROOT_URL,
    //     // });
    //
    //     let channel = window.Echo.channel('update');
    //
    //     channel.listen('BookmarkEvent', function (data) {
    //         // console.log('link', data);
    //
    //         if (data.message.link === bookmarkData.link.uuid) {
    //             axiosBookmark(bookmarkData.bookmark_group_id, bookmarkData.id, (response) => {
    //                 if (response.data.response.data) {
    //                     setBookmarkData(response.data.response.data);
    //                 }
    //             }, null).then();
    //         }
    //     });
    //
    //     // console.dir('channel', channel);
    // },[]);

    useEffect(() => {
        // console.log(props.bookmarkData);
        setBookmarkData(props.bookmarkData);
    }, [props.bookmarkData]);

    useEffect(() => {
        // console.log(bookmarksState.draggableBookmark);
        setDraggableBookmark(bookmarksState.draggableBookmark);
    }, [bookmarksState.draggableBookmark]);

    useEffect(() => {
        // console.log(bookmarksState.isShowDraggableBookmark);
        setIsShowDraggableBookmark(bookmarksState.isShowDraggableBookmark);
    }, [bookmarksState.isShowDraggableBookmark]);

    // useEffect(() => {
    //     if (props.bookmarksGroupBlock) {
    //         const bookmarksGroupBlockWidth = props.bookmarksGroupBlock.width;
    //         const countBlock = Math.round((bookmarksGroupBlockWidth) / (246 + 24));
    //         const blockWidth = (bookmarksGroupBlockWidth - (24 * countBlock)) / countBlock;
    //         // console.log(bookmarksGroupBlockWidth, countBlock, blockWidth);

    //         const bookmarkBlockSize = {
    //             width: blockWidth,
    //             height: (blockWidth / 1.61834)
    //         }
    //         // console.log(bookmarkBlockSize);
    //         setBookmarkBlock(bookmarkBlockSize);

    //         return () => {
    //             setBookmarkBlock(null);
    //         };
    //     }
    // }, [props.bookmarksGroupBlock]);

    useEffect(() => {
        return () => {
            setBookmarkData(null);
        };
    }, []);

    const onContextMenu = (event, bookmarkData) => {
        event.preventDefault();
        bookmarkDraggable(bookmarksDispatch, null).then();
        bookmarkModal(bookmarksDispatch, bookmarkData).then();
    }

    const openURL = (event, url) => {
        if (event.button === 0) {
            // Плохо отрабатывает анимация из-за потери фокуса при переходе на другую страницу.
            // Возможно css анимация.
            bookmarkDraggable(bookmarksDispatch, null).then();

            // Открываем ссылку в новом окне браузера.
            // noopener,noreferrer,resizable,scrollbars,status (доп. параметры).
            const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
            if (newWindow) { newWindow.opener = null; }
        }
    }

    const handleBookmarkDelete = (uuid) => {
        axios.delete(
            '/api/bookmark/' + uuid + '/delete',
            {
                withCredentials: false,
                baseURL: ROOT_URL,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + localStorage.getItem('tokenSession')
                }
            }).then((response) => {
            removingLinkFromData(uuid);
        }).catch((err) => {
            // notification('Что то пошло не так, попробуйте повторить чуть позже', 'red');
        });
    }

    const handleBookmarkDeleteForever = (bookmarkId) => {
        axios.delete(
            '/api/links/group/' + bookmarkData.bookmark_group_id + '/link/' + bookmarkId + '/delete',
            {
                withCredentials: false,
                baseURL: ROOT_URL,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + localStorage.getItem('tokenSession')
                }
            }).then((response) => {
            removingLinksDeletedData(bookmarkId);
        }).catch((err) => {
            // notification('Что то пошло не так, попробуйте повторить чуть позже', 'red');
        });
    }

    const handleBookmarkRecovery = (bookmarkId) => {
        axios.get(
            '/api/links/group/' + bookmarkData.bookmark_group_id + '/link/' + bookmarkId + '/recovery',
            {
                withCredentials: false,
                baseURL: ROOT_URL,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + localStorage.getItem('tokenSession')
                }
            }).then((response) => {
            removingLinksDeletedData(bookmarkId);
        }).catch((err) => {
            // notification('Что то пошло не так, попробуйте повторить чуть позже', 'red');
        });
    }

    const handleBookmarkUpdate = (bookmarkId) => {
        axios.get(
            '/api/links/group/' + bookmarkData.bookmark_group_id + '/link/' + bookmarkId + '/update',
            {
                withCredentials: false,
                baseURL: ROOT_URL,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + localStorage.getItem('tokenSession')
                }
            }).then((response) => {
                // Что то бы придумать на этот счет при успешном запуске обновления.
        }).catch((err) => {
            // notification('Что то пошло не так, попробуйте повторить чуть позже', 'red');
        });
    }

    function removingLinksDeletedData(bookmarkId) {
        const bookmarksGroup = bookmarksState.linksDeleted;

        bookmarksGroup.forEach((bookmark, index) => {
            if (bookmark.bookmark) {
                if (Number(bookmark.bookmark.id) === Number(bookmarkId)) {
                    bookmarksGroup.splice(index, 1);
                }
            }
        });

        saveLinksDeleted(bookmarksDispatch, bookmarksGroup);
    }

    function removingLinkFromData(uuid) {
        const bookmarksGroup = bookmarksState.bookmarksGroup;

        bookmarksGroup.bookmarks.forEach((bookmark, index) => {
            if (bookmark.uuid === uuid) {
                bookmarksGroup.bookmarks.splice(index, 1);
            }
        });

        saveBookmarksGroup(bookmarksDispatch, bookmarksGroup);
    }

    const onDraggableLinkMouseDown = (linkDraggable, event) => {
        if (event.button === 0) {
            bookmarkDraggable(bookmarksDispatch, linkDraggable).then();
        }
    }

    const onDraggableLinkMouseOver = () => {
        setIsHover(true);
    }

    const onDraggableLinkMouseLeave = () => {
        setIsHover(false);
    }

    const onDraggableLinkMouseUp = (linksGroupId, linkInGroupId) => {
        const bookmarksGroup = bookmarksState.bookmarksGroup;
        const draggableLink = bookmarksState.draggableBookmark;

        if ((Number(linksGroupId) === Number(draggableLink.bookmark_group_id)) &&
            (Number(linkInGroupId) !== Number(draggableLink.id))) {

            bookmarkDraggable(bookmarksDispatch, null).then();

            let old_index = null;
            let new_index = null;

            bookmarksGroup.bookmarks.forEach((link, index) => {
                if (Number(link.id) === Number(draggableLink.id)) {
                    old_index = index;
                }

                if (Number(link.id) === Number(linkInGroupId)) {
                    new_index = index;
                }
            });

            bookmarksGroup.bookmarks = dndArray(bookmarksGroup.bookmarks, old_index, new_index);

            saveBookmarksGroup(bookmarksDispatch, bookmarksGroup);

            let groupDataReverse = [];
            let layoutLinks = [];

            bookmarksGroup.bookmarks.forEach((link) => {
                groupDataReverse.push(link);
            });

            groupDataReverse.reverse();
            groupDataReverse.forEach((link, index) => {

                let object = {
                    link_in_group_index: index,
                    link_in_group_id: link.id
                }

                layoutLinks.push(object);
            });

            layoutLinks = JSON.stringify(layoutLinks);

            let formData = new FormData();
            formData.append('links_in_group_layout', layoutLinks);

            axios.post(
                '/api/links/layout',
                formData,
                {
                    withCredentials: false,
                    baseURL: ROOT_URL,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: 'Bearer ' + localStorage.getItem('tokenSession')
                    }
                }).then((response) => {
            }).catch((err) => {
                // notification('Что то пошло не так, попробуйте повторить чуть позже', 'red');
            });
        }

        else {
            bookmarkDraggable(bookmarksDispatch, null).then();
        }
    }

    const onDraggableClean = () => {
        bookmarkDraggable(bookmarksDispatch, null).then();
    }

    return (bookmarkData && (
        // <InView>
        // {({ inView, ref, entry }) => (

        <div 
        // ref={ref}
            className={
                ((!!draggableBookmark && !!isShowDraggableBookmark) &&
                    ((draggableBookmark.id === bookmarkData.id) ||
                    !!bookmarkData.deleted_at)) ? 
                "BookmarkBox__wrapper default" : "BookmarkBox__wrapper"
            } 
            style={{
                position: 'absolute',
                top: props.top ? props.top : 0,
                left: props.left ? props.left : 0,
                // width:  bookmarksGroupBlock ? bookmarksGroupBlock.width : 246,
                width: props.width ? props.width : 270 - 24,
            }}
        >
            {!!bookmarkData.link && (
            <a 
                href={bookmarkData.link.url}
                target={"_blank"} rel="noopener noreferrer"
                // Аннулирование основных действий <a>, оставляя только клик по колесику.
                onMouseDown={event => event.preventDefault()}
                onMouseUp={event => event.preventDefault()}
                onClick={event => event.preventDefault()}>
                <div 
                    className="BookmarkBox__block"
                    onContextMenu={event => onContextMenu(event, bookmarkData)}
                    onMouseDown={event => onDraggableLinkMouseDown(bookmarkData, event)} 
                    onMouseOver={() => onDraggableLinkMouseOver()} 
                    onMouseLeave={() => onDraggableLinkMouseLeave()} 
                    onMouseUp={
                        (!!draggableBookmark && !!isShowDraggableBookmark) 
                        ? (!bookmarkData.deleted_at 
                        ? () => onDraggableLinkMouseUp(bookmarkData.bookmark_group_id, bookmarkData.id) 
                        : () => onDraggableClean()) 
                        : event => openURL(event, bookmarkData.link.url)}>
                    <div className="Block__header">
      
                        {/* {inView && ( */}
                            <div className="Header__image">
                                {!!bookmarkData.link && !!bookmarkData.link.image && bookmarkData.link.image && (
                                    <PreloadImage 
                                        className={'PreloadImage'}
                                        src={bookmarkData.link.image}
                                        innerStyle={{
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "cover",
                                            backgroundPosition: "center top"
                                        }}
                                        lazy={true}/>
                                )}

                                {/*{(!bookmarkData.link.meta_tag_image && */}
                                {/*            !!bookmarkData.link.link_screenshot) && (*/}
                                {/*    <PreloadImage */}
                                {/*        className={'PreloadImage'}*/}
                                {/*        src={!!bookmarkData.link.link_screenshot && (*/}
                                {/*            ROOT_URL + 'service/screenshot/' + bookmarkData.link.link_screenshot)}*/}
                                {/*        innerStyle={{*/}
                                {/*            backgroundRepeat: "no-repeat",*/}
                                {/*            backgroundSize: "cover",*/}
                                {/*            backgroundPosition: "center top"*/}
                                {/*        }}*/}
                                {/*        lazy={true}/>*/}
                                {/*)}*/}
                            </div>
                        {/* )} */}

                        {(!!bookmarkData.link && !bookmarkData.link.image) && (
                            <div className="Header__status">
                                <svg width="42" height="42" viewBox="0 0 42 42">
                                    <g transform="translate(-59.318 -146.867)">
                                        <rect width="42" height="42" transform="translate(59.318 146.867)" fill="none" opacity="0.39"/>
                                        <g transform="matrix(0.966, 0.259, -0.259, 0.966, -286.101, -764.847)">
                                            <path d="M-5075.764-887h-1.03a2,2,0,0,1-2-2v-26a4,4,0,0,1,4-4h18a4,4,0,0,1,4,4v26a2,2,0,0,1-2,2h-1.03l-5.021-5.021-4.949-4.951-9.971,9.971Z" transform="translate(5660.793 1709.001)" fill="#1f1f1f"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        )}

                        {(!!bookmarkData.link && !!bookmarkData.link.status &&
                        (bookmarkData.link.status !== '200')) && (
                            <div className="Header__status">
                                <svg width="42" height="42" viewBox="0 0 42 42">
                                    <g transform="translate(-78 -781)">
                                        <rect width="42" height="42" transform="translate(78 781)" fill="none" opacity="0.39"/>
                                        <path d="M31.64,24.445a2.668,2.668,0,0,1-2.31,4H2.669a2.668,2.668,0,0,1-2.31-4L13.69,1.332a2.668,2.668,0,0,1,4.62,0ZM16,19.667a2.556,2.556,0,1,0,2.556,2.556A2.556,2.556,0,0,0,16,19.667Zm-2.426-9.186.412,7.556a.667.667,0,0,0,.666.63h2.7a.667.667,0,0,0,.666-.63l.412-7.556a.667.667,0,0,0-.666-.7H14.239A.667.667,0,0,0,13.573,10.481Z" transform="translate(83 788)" fill="#fd0"/>
                                    </g>
                                </svg>
                            </div>
                        )}

                        {!bookmarkData.link && (
                            <div className="Header__upload">
                                <svg width="24" height="24" viewBox="0 0 24 24">
                                    <g transform="translate(-610 -375)">
                                        <g transform="translate(18 -8)">
                                            <g transform="translate(592 383)" fill="none" stroke="#0061ff" strokeLinecap="round" strokeWidth="3" strokeDasharray="1 1" opacity="0.07">
                                                <rect width="24" height="24" rx="12" stroke="none"/>
                                                <rect x="1.5" y="1.5" width="21" height="21" rx="10.5" fill="none"/>
                                            </g>
                                            <g transform="translate(592 383)" fill="none" stroke="#0061ff" strokeLinecap="round" strokeWidth="3" strokeDasharray="24 64">
                                                <rect width="24" height="24" rx="12" stroke="none"/>
                                                <rect x="1.5" y="1.5" width="21" height="21" rx="10.5" fill="none"/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        )}

                        {/*{!!bookmarkData.deleted_at && (*/}
                        {/*    <div className="Header__deleted">*/}
                        {/*        <svg width="24" height="24" viewBox="0 0 24 24">*/}
                        {/*            <g transform="scale(-1 1)" transform-origin="12 12">*/}
                        {/*                <g transform="translate(-610 -375)">*/}
                        {/*                    <g transform="translate(18 -8)">*/}
                        {/*                        <g transform="translate(592 383)" fill="none" stroke="#e93a28" strokeLinecap="round" strokeWidth="3" strokeDasharray="1 1" opacity="0.07">*/}
                        {/*                            <rect width="24" height="24" rx="12" stroke="none"/>*/}
                        {/*                            <rect x="1.5" y="1.5" width="21" height="21" rx="10.5" fill="none"/>*/}
                        {/*                        </g>*/}
                        {/*                        <g transform="translate(592 383)" fill="none" stroke="#e93a28" strokeLinecap="round" strokeWidth="3" strokeDasharray={deletedAtDash(bookmarkData.deleted_at, 30, 64)  + " 64"}>*/}
                        {/*                            <rect width="24" height="24" rx="12" stroke="none"/>*/}
                        {/*                            <rect x="1.5" y="1.5" width="21" height="21" rx="10.5" fill="none"/>*/}
                        {/*                        </g>*/}
                        {/*                    </g>*/}
                        {/*                </g>*/}
                        {/*            </g>*/}
                        {/*        </svg>*/}
                        {/*    </div>*/}
                        {/*)}*/}

                    </div>
                    <div className="Block__footer">

                        {/* {inView && ( */}
                            <div className="Footer__info">
                                <div className="Info__title">
                                    {!!bookmarkData.link && !!bookmarkData.link.title &&
                                        linkTitle(bookmarkData.link.title)}
                                    {!!bookmarkData.link && !bookmarkData.link.title && (
                                        <div className="Title__dash">
                                            <svg width="24" height="19" viewBox="0 0 24 19">
                                                <g transform="translate(0 -9)" opacity="0.02">
                                                    <rect width="24" height="19" transform="translate(0 9)" fill="none"/>
                                                    <rect width="24" height="6" rx="3" transform="translate(0 16)" fill="#fff"/>
                                                </g>
                                            </svg>
                                        </div>
                                    )}
                                </div>
                                <div className="Info__signature">
                                    {/*{!!bookmarkData.link && !!bookmarkData.link.favicon && (*/}
                                    {/*  <img*/}
                                    {/*    className="Signature__favicon"*/}
                                    {/*    height={16}*/}
                                    {/*    width={16}*/}
                                    {/*    src={faviconUrl(bookmarkData.link.link_url, bookmarkData.link.favicon)}/>*/}
                                    {/*)}*/}
                                    {!!bookmarkData.link && !!bookmarkData.link.favicon && (
                                      <img
                                        className="Signature__favicon"
                                        height={16}
                                        width={16}
                                        src={bookmarkData.link.favicon}/>
                                    )}
                                    <div className="Signature__text">
                                        {(!!bookmarkData.link.host && bookmarkData.link.host.replace('www.', '')) || bookmarkData.link.url}
                                    </div>
                                </div>
                            </div>
                        {/* )} */}

                    </div>

                </div>
            </a>
            )}

            {!bookmarkData.deleted_at && (
                <div 
                    className="Wrapper__shift"
                    style={{
                        backgroundColor: ((
                            draggableBookmark && isHover && 
                            (draggableBookmark.id !== bookmarkData.id)) && 
                            "white"),
                    }}
                />
            )}

            {((!!bookmarkData.link && !bookmarkData.link.status) ||
                //{/* (bookmarkData.link.link_status === 'http_error')) && */}
                !draggableBookmark && !bookmarkData.deleted_at) && (
                <React.Fragment>
                    <div 
                        className="Bookmark__delete"
                        onClick={() => handleBookmarkDelete(bookmarkData.uuid)}>
                        <svg width="32" height="32" viewBox="0 0 32 32">
                            <g transform="translate(32) rotate(90)">
                                <rect width="32" height="32" fill="none"/>
                                <rect width="24" height="24" rx="12" transform="translate(4 4)" fill="#de1717"/>
                                <rect width="4" height="12" rx="1" transform="translate(14 10)" fill="#fff"/>
                            </g>
                        </svg>
                    </div>
                </React.Fragment>
            )}

            {/*{(!!bookmarkData.link && !!bookmarkData.link.status &&*/}
            {/*    ///!* (bookmarkData.link.link_status === 'http_error') && *!/*/}
            {/*    !draggableBookmark && !bookmarkData.deleted_at) && (*/}
            {/*    <React.Fragment>*/}
            {/*        <div */}
            {/*            className="Bookmark__update"*/}
            {/*            onClick={() => handleBookmarkUpdate(bookmarkData.id)}/>*/}
            {/*    </React.Fragment>*/}
            {/*)}*/}

            {(
                //{/* (!bookmarkData.link.link_status || */}
                //{/* (bookmarkData.link.link_status === 'http_error')) && */}
                !draggableBookmark && !!bookmarkData.deleted_at) && (
                <React.Fragment>
                    <div 
                        className="Bookmark__delete Bookmark__delete_forever"
                        onClick={() => handleBookmarkDeleteForever(bookmarkData.id)}>
                        <svg width="32" height="32" viewBox="0 0 32 32">
                            <g transform="translate(32) rotate(90)">
                                <rect width="32" height="32" fill="none"/>
                                <rect width="24" height="24" rx="12" transform="translate(4 4)" fill="#de1717"/>
                                <g transform="translate(4 28) rotate(-90)">
                                    <path d="M0,0H24V24H0Z" fill="none"/>
                                    <path id="fire-solid" d="M6.75.745A.751.751,0,0,0,5.37.338C1.5,5.995,7,6.249,7,9a2,2,0,1,1-4-.03V6.3a.75.75,0,0,0-1.295-.516A6.249,6.249,0,0,0,0,10a6,6,0,0,0,12,0C12,4.678,6.75,3.968,6.75.745Z" transform="translate(6 4)" fill="#fff"/>
                                </g>
                            </g>
                        </svg>
                    </div>
                </React.Fragment>
            )}

            {(
                //{/* (!bookmarkData.link.link_status || */}
                //{/* (bookmarkData.link.link_status === 'http_error')) && */}
                !draggableBookmark && !!bookmarkData.deleted_at) && (
                <React.Fragment>
                    <div 
                        className="Bookmark__recover"
                        onClick={() => handleBookmarkRecovery(bookmarkData.id)}/>
                </React.Fragment>
            )}

        </div>

        // )}
        // </InView>
    ));
});

export default BookmarkBox;