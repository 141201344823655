import React, {useContext, useEffect, useState} from "react";
import "./MenuBookmarks.scss";
import {NavLink} from "react-router-dom";
import ModalBookmarksGroupAdd from "./ModalBookmarksGroupAdd";
import useToggle from "../../../../../hooks/useToggle";
import {BookmarksContext} from "../../../../../contexts/bookmarks";
import {ScrollBlock} from "../../../../../components/ScrollBlock/ScrollBlock";

function BookmarksMenu(props) {
  const {bookmarksState} = useContext(BookmarksContext);
  const [bookmarksData, setBookmarksData] = useState(null);

  const [isModalAdd, setIsModalAdd] = useToggle();

  useEffect(() => {
    setBookmarksData(bookmarksState.bookmarksData);
  }, [bookmarksState.bookmarksData]);

  return (
    !!bookmarksData &&
    <>

    <ScrollBlock
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div className="MenuGroups__menu">
          <ul>
            <li>
              <NavLink to={'/my/bookmarks/other'}>
                <div
                  className="Menu__link Menu__link_top">
                  Разное
                </div>
              </NavLink>
            </li>
            <li>
              <div className="Menu__hr"/>
            </li>
            {!!bookmarksData.groups &&
              bookmarksData.groups.map(group => (
                <li key={'draggable_' + group.uuid}>
                  <NavLink to={'/my/bookmarks/group/' + group.uuid}>
                    <div
                      className="Menu__link">
                      {group.title}
                    </div>
                  </NavLink>
                </li>
              ))}
            <li>
              <div
                className={"BookmarksGroup__add"}
                onClick={() => setIsModalAdd()}/>
            </li>
          </ul>
      </div>
    </ScrollBlock>

      {!!isModalAdd && (
        <ModalBookmarksGroupAdd
          handleClose={() => setIsModalAdd()}
          historyPush={props.historyPush}/>
      )}
    </>
  )
}

export default BookmarksMenu;
