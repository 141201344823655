import React from 'react'
import UsersList from './UsersList'
import useUsers from './useUsers'

function Users() {
  return (
    <UsersList {...useUsers()} />
  )
}

export default Users