import React, {useContext, useRef} from "react";
import useToggle from "../../../../../hooks/useToggle";
import axios from "axios";
import {ROOT_URL} from "../../../../../config/url";
import {BookmarksContext, saveBookmarksData} from "../../../../../contexts/bookmarks";
import {Input, isValidateNullText, Loading, Modal, useObject} from "@vokymlak/kabinet-ui";
import {toast} from "sonner";
import {useParams} from 'react-router-dom';

function ModalBookmarksGroupEdit(props) {
  const {bookmarksState, bookmarksDispatch} = useContext(BookmarksContext);
  const [isHandleSubmit, setIsHandleSubmit] = useToggle();
  const {bookmarksGroupId} = useParams();

  const inputTitle = useRef(null);

  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/bookmark-group/" + props.bookmarkGroupData.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const handleEdit = (event) => {
    event.preventDefault();

    const title = inputTitle.current.value;

    if (isValidateNullText(title)) {
      toast.error("Заполните все обязательные поля");
      return;
    }

    setIsHandleSubmit();

    const jsonData = JSON.stringify({
      title: title,
    });

    axios.put(
      'api/bookmark-group/' + props.bookmarkGroupData.uuid + '/edit',
      jsonData,
      {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('tokenSession'),
        },
      }).then((response) => {
      if (!!response.data) {
        props.bookmarkGroupData.title = response.data.title;

        bookmarksState.bookmarksData.groups.map(group => {
          if (group.uuid === bookmarksGroupId) {
            group.title = response.data.title;
          }
        });

        saveBookmarksData(bookmarksDispatch, {...bookmarksState.bookmarksData});
      }

      props.handleClose();
    })
      .catch((error) => {
        toast.error("Что то пошло не так, попробуйте чуть позже");
      })
      .finally(() => {
        setIsHandleSubmit();
      });
  }

  return (
    !!object &&
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={event => (handleEdit(event))}>
        <div
          className="Form__title"
          style={{fontSize: 19, fontWeight: 700}}
        >
          {(!!object.title && object.title) || "-"}
          <div className="Text__signature">Группа вкладок</div>
        </div>

        <div className="Form__field">
          <Input autoFocus={true} reference={inputTitle} label="Название *"
                 value={!!object.title && object.title}/>
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue"
                  type="submit"
                  style={{
                    position: "relative"
                  }}
                  disabled={isHandleSubmit ? 'disabled' : null}>
            Применить
            <div style={{position: "absolute", right: 6, top: 6}}>
              {isHandleSubmit ? <Loading white={true}/> : null}
            </div>
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalBookmarksGroupEdit;
