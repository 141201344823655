import { useState, useEffect } from 'react'

// Hook
function useScroll(baronId) {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [scrollTop, setScrollTop] = useState(0)
  useEffect(() => {
    // Handler to call on window resize
    function handleScroll() {
      // Set window width/height to state
      setScrollTop(
        document.getElementById(baronId).scrollTop
          ? document.getElementById(baronId).scrollTop
          : 0,
      )
    }
    // Add event listener
    document.getElementById(baronId).addEventListener('scroll', handleScroll)
    // Call handler right away so state gets updated with initial window size
    handleScroll()
    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleScroll)
  }, []) // Empty array ensures that effect is only run on mount
  return scrollTop
}

export default useScroll
