import React, {Fragment} from "react";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {Input, Loading} from "@vokymlak/kabinet-ui";

const ParserView = (props) => {
  const {
    object,
    inputLinkUrl,
    onChangeParser,
    isRequest,
    openLink,
    option,
  } = props;

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div className="Title__text" style={{display: "flex"}}>
            Парсинг
          </div>

          <div className="Title__settings">
          </div>
        </div>

        <div className="Page__content">
          <div>
            <div className="Form__field Form__field_last">
              <Input reference={inputLinkUrl} type="text" label="ссылка"/>
            </div>
            <br/>
            <div className="Form__actions Form__actions_center">
              <button
                className="Action__button Action__button_all Action__button_blue"
                onClick={!isRequest ? () => onChangeParser() : () => null}
              >
                {!isRequest ? "Начать" : <Loading size="medium" color="blue"/>}
              </button>
            </div>
          </div>
          <div>
            {!!object && (
              <table className="Table">
                <tbody>

                {!!object.link && (
                  <>
                    <tr className="Table__tr">
                      <td className="Table__td" style={{width: 162}}>
                        <div className="Td__title">url</div>
                      </td>
                      <td className="Table__td">
                        {(!!object.link.url && (
                          <span
                            onClick={() => openLink(object.link.url)}
                            style={{
                              cursor: "pointer",
                              color: "#2196f3",
                              textDecoration: "underline",
                            }}
                          >
                                  {object.link.url}
                              </span>
                        )) || "-"}
                      </td>
                    </tr>

                    <tr className="Table__tr">
                      <td className="Table__td">
                        <div className="Td__title">status</div>
                      </td>
                      <td className="Table__td">
                        {(!!object.link.status && object.link.status) || "-"}
                      </td>
                    </tr>

                    <tr className="Table__tr">
                      <td className="Table__td">
                        <div className="Td__title">href</div>
                      </td>
                      <td className="Table__td">
                        {(!!object.link.href && object.link.href) || "-"}
                      </td>
                    </tr>

                    <tr className="Table__tr">
                      <td className="Table__td">
                        <div className="Td__title">host</div>
                      </td>
                      <td className="Table__td">
                        {(!!object.link.host && object.link.host) || "-"}
                      </td>
                    </tr>

                    <tr className="Table__tr">
                      <td className="Table__td">
                        <div className="Td__title">pathname</div>
                      </td>
                      <td className="Table__td">
                        {(!!object.link.pathname && object.link.pathname) || "-"}
                      </td>
                    </tr>

                    <tr className="Table__tr">
                      <td className="Table__td">
                        <div className="Td__title">search</div>
                      </td>
                      <td className="Table__td">
                        {(!!object.link.search && object.link.search) || "-"}
                      </td>
                    </tr>

                    <tr className="Table__tr">
                      <td className="Table__td">
                        <div className="Td__title">content_type</div>
                      </td>
                      <td className="Table__td">
                        {(!!object.link.content_type && object.link.content_type) || "-"}
                      </td>
                    </tr>

                    <tr className="Table__tr">
                      <td className="Table__td">
                        <div className="Td__title">title</div>
                      </td>
                      <td className="Table__td">
                        {(!!object.link.title && object.link.title) || "-"}
                      </td>
                    </tr>

                    <tr className="Table__tr">
                      <td className="Table__td">
                        <div className="Td__title">favicon</div>
                      </td>
                      <td className="Table__td">
                        {(!!object.link.favicon && object.link.favicon) || "-"}
                        {(!!object.link.favicon && (
                          <img
                            src={object.link.favicon}
                          />
                        ))}
                      </td>
                    </tr>

                    <tr className="Table__tr">
                      <td className="Table__td">
                        <div className="Td__title">description</div>
                      </td>
                      <td className="Table__td">
                        {(!!object.link.description && object.link.description) || "-"}
                      </td>
                    </tr>

                    <tr className="Table__tr">
                      <td className="Table__td">
                        <div className="Td__title">keywords</div>
                      </td>
                      <td className="Table__td">
                        {(!!object.link.keywords && object.link.keywords) || "-"}
                      </td>
                    </tr>

                    <tr className="Table__tr">
                      <td className="Table__td" style={{
                        verticalAlign: "top"
                      }}>
                        <div className="Td__title">image</div>
                      </td>
                      <td className="Table__td">
                        {(!!object.link.image && object.link.image) || "-"}
                        {(!!object.link.image && (
                          <img
                            src={object.link.image}
                          />
                        ))}
                      </td>
                    </tr>
                  </>
                )}

                <tr className="Table__tr">
                  <td className="Table__td" style={{
                    verticalAlign: "top"
                  }}>
                    <div className="Td__title">
                      Скриншот
                    </div>
                  </td>
                  <td className="Table__td">
                    {(!!object.full_screenshot && (
                      <img
                        src={"data:image/png;base64," + object.full_screenshot}
                        style={{
                          width: 640,
                        }}
                      />
                    )) || "-"}
                  </td>
                </tr>

                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </ScrollBlock>
  );
};

export default ParserView;
