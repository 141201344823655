import React, {createContext, useReducer, useEffect} from 'react';
import {initialState, bookmarksReducer} from './bookmarksReducer';
import {bookmarksInit} from './bookmarksAction';

export const BookmarksContext = createContext(initialState);

export const BookmarksProvider = ({children}) => {
    const [bookmarksState, bookmarksDispatch] = useReducer(bookmarksReducer, initialState);

    useEffect(() => {
        bookmarksInit(bookmarksDispatch).then();
    }, [children]);

    return (
        <BookmarksContext.Provider value={{bookmarksState, bookmarksDispatch}}>
            {children}
        </BookmarksContext.Provider>
    );
};
