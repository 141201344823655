import React, {useState} from "react";
import {NavLink, Route} from "react-router-dom";
import {
  useProfile,
  isProfileRoleTypeTags,
} from "@vokymlak/kabinet-ui";
import {
  ROLE_ADMINISTRATOR,
  ROLE_CLIENT,
  ROLE_DIRECTOR,
  ROLE_EMPLOYEE,
} from "../../config/constants";

function MenuRouting(props) {
  const { profile } = useProfile();
  const {setIsModalProfile} = props;

  const onChange = () => {
    if (!!props.onChange) {
      props.onChange()
    }
  }

  return (
    <>
      {isProfileRoleTypeTags(profile, [
        ROLE_CLIENT
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/my/bookmarks"} onClick={() => onChange()}>
              Вкладки
            </NavLink>
          </li>
        </ul>
      )}

      {/*{isProfileRoleTypeTags(profile, [*/}
      {/*  ROLE_CLIENT*/}
      {/*]) && (*/}
      {/*  <ul className="Menu__list Menu__list_box">*/}
      {/*    <li className="List__link">*/}
      {/*      <NavLink className={"Link__text"} to={"/my/tariff"} onClick={() => onChange()}>*/}
      {/*        Тарифный план*/}
      {/*      </NavLink>*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*)}*/}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/tariffs-selling"} onClick={() => onChange()}>
              Оформление тарифов
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/tariffs-setting"} onClick={() => onChange()}>
              Настройка тарифов
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/tariffs-codes"} onClick={() => onChange()}>
              Промо-коды для тарифов
            </NavLink>
          </li>
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_EMPLOYEE,
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/tariffs-selling"} onClick={() => onChange()}>
              Оформление тарифов
            </NavLink>
          </li>
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
        ROLE_EMPLOYEE,
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/clients"} onClick={() => onChange()}>
              Клиенты
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/employees"} onClick={() => onChange()}>
              Сотрудники
            </NavLink>
          </li>
          {isProfileRoleTypeTags(profile, [ROLE_ADMINISTRATOR]) && (
            <li className="List__link">
              <NavLink className={"Link__text"} to={"/accounts"} onClick={() => onChange()}>
                Аккаунты
              </NavLink>
            </li>
          )}
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__header">
            <div className="Header__text">Данные</div>
          </li>
          <li className="List__link" style={{
            paddingTop: 12
          }}>
            <NavLink className={"Link__text"} to={"/links"} onClick={() => onChange()}>
              Ссылки
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/users"} onClick={() => onChange()}>
              Пользователи
            </NavLink>
          </li>
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__header">
            <div className="Header__text">Парсинг</div>
          </li>
          <li className="List__link" style={{
            paddingTop: 12
          }}>
            <NavLink className={"Link__text"} to={"/parser-link"} onClick={() => onChange()}>
              Парсинг ссылок
            </NavLink>
          </li>
        </ul>
      )}
    </>
  );
}

export default MenuRouting;
