import React from "react";
import styles from "./Users.module.scss";
import {User} from "./components";

const UsersList = (props) => {
  const {
    list,
    isUpdateList,
    setIsUpdateList,
    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,
    lastVisitText,
    lastVisitColor,
    lastVisitDataTime,
  } = props;

  return (
    <div className="Page">
      <div className="Page__title">
        <div className="Title__text">
          Пользователи{!!list && list.length > 0 && " # " + list.length}
        </div>
        <div className="Title__settings">
          {!!list && (
            <div
              className="Button__text"
              onClick={() => changeAllUnroll(list, "id")}
            >
              {list.length === unrolls.length ? "Свернуть" : "Развернуть"}
            </div>
          )}
          <div
            style={{
              marginLeft: 12,
            }}
            className="Button__text"
            onClick={() => setIsUpdateList(!isUpdateList)}
          >
            Обновить
          </div>
        </div>
      </div>

      <div className="Page__content">
        <ul className="List">
          {!!list &&
            list.map((user, index) => (
              <React.Fragment key={"user_" + index}>
                <li
                  className={
                    isUnroll(user.id) ? "List__item active" : "List__item"
                  }
                  onClick={() => changeUnroll(user.id)}
                >
                  <div className="Item__title">
                    <div className="Element__title">
                      {user.id} | {user.surname} {user.name} ({user.email})
                    </div>
                  </div>
                  <div className="Item__settings">
                    <div className="Element__datetime">
                      (
                      {lastVisitText(
                        user.last_visit
                          ? new Date(user.last_visit).getTime()
                          : 0
                      )}
                      ){" "}
                      {lastVisitDataTime(
                        user.last_visit
                          ? new Date(user.last_visit).getTime()
                          : 0
                      )}
                      <div
                        className="Marker Marker_left"
                        style={{
                          background: lastVisitColor(
                            user.last_visit
                              ? new Date(user.last_visit).getTime()
                              : 0
                          ),
                        }}
                      />
                    </div>
                  </div>
                </li>

                {isUnroll(user.id) && (
                  <li className="List__content">
                    <div>
                      <User user={user} />
                    </div>
                    <div className="Content__footer">
                      <div className="Footer__title"></div>
                      <div className="Footer__settings"></div>
                    </div>
                  </li>
                )}
              </React.Fragment>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default UsersList;
