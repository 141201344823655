import axios from "axios";
import {
    BOOKMARKS_REQUEST,
    BOOKMARKS_REQUEST_ERROR,
    CHECKING_BUFFER,
    BOOKMARKS_REQUEST_SUCCESS,
    BOOKMARK_DRAGGABLE,
    BOOKMARK_DRAGGABLE_SHOW,
    BOOKMARK_MODAL,
    BOOKMARK_BUFFER, BOOKMARKS_GROUP, SELECT_BOOKMARK, LINKS_DELETED
} from "../actionTypes";
import {ROOT_URL} from "../../config/url";

export async function bookmarksInit(dispatch) {
    dispatch({type: BOOKMARKS_REQUEST});

    try {
        const response = await axios.get(
            '/api/bookmarks',
            {
                withCredentials: false,
                baseURL: ROOT_URL,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + localStorage.getItem('tokenSession')
                }
            });

        if (response.data) {
            dispatch({type: BOOKMARKS_REQUEST_SUCCESS, bookmarksData: response.data});
        }

        else {
            dispatch({type: BOOKMARKS_REQUEST_ERROR, bookmarksError: 'Что то пошло не так, попробуйте чуть позже'});
        }
    }

    catch (error) {
        dispatch({type: BOOKMARKS_REQUEST_ERROR, bookmarksError: 'Что то пошло не так, попробуйте чуть позже'});
    }
}

export async function checkingBuffer(dispatch) {
    navigator.clipboard.readText()
        .then(text => {
            // `text` содержит текст, прочитанный из буфера обмена
            try {
                let parser = new URL(text);

                if (parser.protocol && parser.host) {
                    // console.log(parser.protocol + '//' + parser.host);
                    dispatch(saveBuffer(text));
                }

                else {
                    dispatch(cleanBuffer());
                }
            }

            catch {
                dispatch(cleanBuffer());
            }
        })
        .catch(err => {
            // возможно, пользователь не дал разрешение на чтение данных из буфера обмена
            // console.log('Something went wrong', err);
        });
}

export function saveBookmarksData(dispatch, bookmarksData) {
    dispatch({type: BOOKMARKS_REQUEST_SUCCESS, bookmarksData: bookmarksData});
}

export function saveBookmarksGroup(dispatch, bookmarksGroup) {
    dispatch({type: BOOKMARKS_GROUP, bookmarksGroup: bookmarksGroup});
}

export function saveLinksDeleted(dispatch, linksDeleted) {
    dispatch({type: LINKS_DELETED, linksDeleted: linksDeleted});
}

export async function selectBookmark(dispatch, selectBookmark) {
    dispatch({type: SELECT_BOOKMARK, selectBookmark: selectBookmark});
}

export async function saveBuffer(dispatch, bookmarkBuffer) {
    dispatch({type: CHECKING_BUFFER, bookmarkBuffer: bookmarkBuffer});
}

export async function cleanBuffer(dispatch) {
    dispatch({type: CHECKING_BUFFER, bookmarkBuffer: null});
}

export async function bookmarkBufferView(dispatch, isBookmarkBuffer) {
    dispatch({type: BOOKMARK_BUFFER, isBookmarkBuffer: isBookmarkBuffer});
}

export async function bookmarkDraggable(dispatch, draggableBookmark) {
    // console.log('DraggableBookmark set.');
    dispatch({type: BOOKMARK_DRAGGABLE, draggableBookmark: draggableBookmark});
}

export async function bookmarkDraggableShow(dispatch, isShowDraggableBookmark) {
    // console.log('DraggableBookmark show.');
    dispatch({type: BOOKMARK_DRAGGABLE_SHOW, isShowDraggableBookmark: isShowDraggableBookmark});
}

export async function bookmarkModal(dispatch, modalBookmark) {
    dispatch({type: BOOKMARK_MODAL, modalBookmark: modalBookmark});
}
