import React, {useState} from "react";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {Icon, useObject} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";
import MyBookmarksPage from "./MyBookmarksPage";
import {NavLink} from "react-router-dom";

function TariffMyBookmarksWrapper() {
  const [isNotTariff, setIsNotTariff] = useState(true);
  const [isTariffRequest, setIsTariffRequest] = useState(true);

  const {
    object: [myTariff],
    update: [isUpdateMyTariff, setIsUpdateMyTariff],
  } = useObject({
    apiUrl: "/api/my/tariff",
    rootUrl: ROOT_URL,
    callbackResponse: () => {
      setIsNotTariff(false);
      setIsTariffRequest(false);
    },
    callbackError: (error) => {
      if (error.response.status === 402) {
        setIsNotTariff(true);
        setIsTariffRequest(false);
      }
    }
  });

  return (
    <>
      {/*{(isTariffRequest || isNotTariff) && (*/}
      {/*  <ScrollBlock style={{width: "100%", height: "100%"}}>*/}
      {/*    <div className="Page">*/}
      {/*      <div style={{*/}
      {/*        width: "100%",*/}
      {/*        height: "100%",*/}
      {/*        display: "flex",*/}
      {/*        flexDirection: "column",*/}
      {/*        flexWrap: "nowrap",*/}
      {/*        justifyContent: "center",*/}
      {/*        alignItems: "center",*/}
      {/*      }}>*/}
      {/*        /!*{isTariffRequest && (*!/*/}
      {/*        /!*  <Loading/>*!/*/}
      {/*        /!*)}*!/*/}

      {/*        /!*{!isTariffRequest && isNotTariff && (*!/*/}
      {/*        /!*  <iframe width="560" height="315" src="https://www.youtube.com/embed/"*!/*/}
      {/*        /!*          title="YouTube video player" frameBorder="0"*!/*/}
      {/*        /!*          style={{*!/*/}
      {/*        /!*            borderRadius: 11,*!/*/}
      {/*        /!*            marginBottom: 24*!/*/}
      {/*        /!*          }}*!/*/}
      {/*        /!*          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"*!/*/}
      {/*        /!*          referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>*!/*/}
      {/*        /!*)}*!/*/}

      {/*        {!isTariffRequest && isNotTariff && (*/}
      {/*          <div style={{*/}
      {/*            fontSize: 17,*/}
      {/*            marginBottom: 42,*/}
      {/*            backgroundColor: "white",*/}
      {/*            padding: "24px",*/}
      {/*            width: 560,*/}
      {/*            borderRadius: 11,*/}
      {/*            display: "flex",*/}
      {/*            flexDirection: "row",*/}
      {/*            justifyContent: "center",*/}
      {/*            alignItems: "flex-start",*/}
      {/*            flexWrap: "nowrap",*/}
      {/*          }}>*/}
      {/*            <div style={{*/}
      {/*              marginRight: 24,*/}
      {/*              height: 48*/}
      {/*            }}>*/}
      {/*              <Icon name={"custom-info"} size={42} color={'#20202075'}/>*/}
      {/*            </div>*/}
      {/*            <div>*/}
      {/*              <br/>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        )}*/}

      {/*        {!isTariffRequest && isNotTariff && (*/}
      {/*          <div style={{*/}
      {/*            textAlign: "center",*/}
      {/*            fontSize: 19*/}
      {/*          }}>*/}


      {/*            Раздел доступен пользователям после приобретения тарифного плана.*/}
      {/*            <div>*/}
      {/*              Перейти в раздел,*/}
      {/*              <NavLink style={{*/}
      {/*                color: "#0064ff",*/}
      {/*                marginLeft: 4,*/}
      {/*                textDecoration: "underline"*/}
      {/*              }} to={"/my/tariff"}>*/}
      {/*                Тарифный план*/}
      {/*              </NavLink>*/}
      {/*              .*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        )}*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </ScrollBlock>*/}
      {/*)}*/}

      {/*{!isTariffRequest && !isNotTariff &&*/}
      {/*  !!myTariff && (*/}
      {/*    <MyProductsPage myTariff={myTariff}/>*/}
      {/*  )}*/}

      {!isTariffRequest && (
          <MyBookmarksPage myTariff={myTariff}/>
        )}
    </>
  );
}

export default TariffMyBookmarksWrapper;
