import { useEffect, useState } from "react";

function useUnroll() {
    const [unrolls, setUnrolls] = useState([])

    useEffect(() => {
        return () => {
            setUnrolls([]);
        }
    }, []);

    const isUnroll = (id) => {
        let repeat = false

        if (unrolls.length > 0) {
            unrolls.map((object) => {
                if (String(object) === String(id)) {
                    repeat = true
                }
            })
        }

        return repeat
    }

    const changeUnroll = (id) => {
        let repeat = false

        if (unrolls.length > 0) {
            unrolls.map((object, index) => {
                if (String(object) === String(id)) {
                    repeat = true
                    unrolls.splice(index, 1)
                }
            })
        }

        if (!repeat) {
            unrolls.push(id)
        }

        setUnrolls([...unrolls])
    }

    const changeAllUnroll = (list, key) => {
        const unroll = []
        if (list.length !== unrolls.length) {
            list.map((object) => {
                unroll.push(object[key])
            })
        }

        setUnrolls([...unroll])
    }

    return {
        unrolls,
        isUnroll,
        changeUnroll,
        changeAllUnroll,
    }
}

export default useUnroll;
