import {
    BOOKMARK_BUFFER,
    BOOKMARK_DRAGGABLE,
    BOOKMARK_DRAGGABLE_SHOW,
    BOOKMARKS_REQUEST_SUCCESS,
    BOOKMARKS_REQUEST,
    BOOKMARKS_REQUEST_ERROR,
    BOOKMARK_MODAL,
    CHECKING_BUFFER, BOOKMARKS_GROUP, SELECT_BOOKMARK,
    LINKS_DELETED
} from "../actionTypes";

export const initialState = {
    bookmarksInit: false,
    bookmarksRequest: false,
    bookmarksError: null,
    bookmarksData: null,

    bookmarksGroup: null,
    linksDeleted: null,

    selectBookmark: null,

    draggableBookmark: null,
    isShowDraggableBookmark: false,

    bookmarkBuffer: null,
    isBookmarkBuffer: false,

    modalBookmark: null,
}

export const bookmarksReducer = (initialState, action) => {
    switch (action.type) {
        case BOOKMARKS_REQUEST:
            return {
                ...initialState, bookmarksRequest: true, bookmarksError: null
            }
        case BOOKMARKS_REQUEST_ERROR:
            return {
                ...initialState, bookmarksRequest: false, bookmarksError: action.bookmarksError
            }
        case BOOKMARKS_REQUEST_SUCCESS:
            return {
                ...initialState, bookmarksInit: true, bookmarksRequest: false, bookmarksError: null, bookmarksData: action.bookmarksData
            }

        case BOOKMARKS_GROUP:
            return {
                ...initialState, bookmarksGroup: action.bookmarksGroup
            }

        case LINKS_DELETED:
            return {
                ...initialState, linksDeleted: action.linksDeleted
            }

        case SELECT_BOOKMARK:
            return {
                ...initialState, selectBookmark: action.selectBookmark
            }

        case BOOKMARK_DRAGGABLE:
            return {
                ...initialState, draggableBookmark: action.draggableBookmark
            }
        case BOOKMARK_DRAGGABLE_SHOW:
            return {
                ...initialState, isShowDraggableBookmark: action.isShowDraggableBookmark
            }

        case BOOKMARK_MODAL:
            return {
                ...initialState, modalBookmark: action.modalBookmark
            }

        case BOOKMARK_BUFFER:
            return {
                ...initialState, isBookmarkBuffer: action.isBookmarkBuffer
            }
        case CHECKING_BUFFER:
            return {
                ...initialState, bookmarkBuffer: action.bookmarkBuffer
            }

        default:
            return initialState;
    }
}
