import React, {useCallback, useContext, useEffect, useState} from "react";
import "../BookmarkGroup/GroupBookmarks.scss";
import useToggle from "../../../../../hooks/useToggle";
import {ROOT_URL} from "../../../../../config/url";
import axios from "axios";

import {BookmarksContext, saveBookmarksGroup} from "../../../../../contexts/bookmarks";
import useWindowSize from "../../../../../hooks/useWindowSize";
import useScroll from "../../../../../hooks/useScroll";

export const useBookmarkGroupOther = () => {
  const {width, height} = useWindowSize();
  const {bookmarksState, bookmarksDispatch} = useContext(BookmarksContext);

  const [bookmarksGroupData, setBookmarksGroupData] = useState(null);
  const [isBookmarksGroupUpdate, setIsBookmarksGroupUpdate] = useToggle();

  const [bookmarksGroupBlock, setBookmarksGroupBlock] = useState(null);

  const [isModalBookmarkAdd, setIsModalBookmarkAdd] = useState(false);

  useEffect(() => {
    document.title = 'Разное';
    return () => {
      document.title = 'Вкладки'
    };
  }, []);

  useEffect(() => {
    axiosBookmarksGroup((response) => {
      if (response.data) {
        saveBookmarksGroup(bookmarksDispatch, response.data);
      }
      let block = document.getElementById("Links");
      block.scrollTop = 0;
    }, null).then();
  }, []);

  useEffect(() => {
    if (bookmarksGroupData) {
      axiosBookmarksGroup((response) => {
        if (response.data) {
          saveBookmarksGroup(bookmarksDispatch, response.data);
        }
      }, null).then();
    }
  }, [isBookmarksGroupUpdate]);

  useEffect(() => {
    setBookmarksGroupData(bookmarksState.bookmarksGroup);
  }, [bookmarksState.bookmarksGroup]);

  async function axiosBookmarksGroup(callbackSuccess, callbackError) {
    axios.get(
      '/api/bookmark-group/' + 'other',
      {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('tokenSession')
        }
      }).then((response) => {
      if (callbackSuccess) callbackSuccess(response);
    }).catch((err) => {
      if (callbackError) callbackError(err);
    });
  }

  const upBookmarksGroup = () => {
    let block = document.getElementById("Links");
    block.scrollTop = 0;
  }

  const closeBookmarksGroup = () => {
    saveBookmarksGroup(bookmarksDispatch, null);
  }

  const bookmarkWidth = 216;

  const pageHeight = (dataBlock) => {
    if (bookmarksGroupData && bookmarksGroupData.bookmarks) {
      const bookmarks = bookmarksGroupData.bookmarks
      const countBlock =
        dataBlock.width > bookmarkWidth + 24
          ? Math.floor(dataBlock.width / (bookmarkWidth + 24))
          : bookmarkWidth + 24

      const lineCount = Math.ceil(bookmarks.length / countBlock)

      return lineCount * (154 + 24)
    }
    return 0
  }

  const boxWidth = (dataBlock) => {
    const countBlock =
      dataBlock.width > bookmarkWidth + 24
        ? Math.floor(dataBlock.width / (bookmarkWidth + 24))
        : bookmarkWidth + 24
    return dataBlock.width / countBlock - 24
  }

  const boxTop = (dataBlock, index) => {
    const countBlock =
      dataBlock.width > bookmarkWidth + 24
        ? Math.floor(dataBlock.width / (bookmarkWidth + 24))
        : bookmarkWidth + 24

    const ind = index + 1

    let lineCount = 0

    if (countBlock < ind) {
      lineCount = Math.floor(index / countBlock)
    }

    return lineCount > 0 ? lineCount * (154 + 24) : 0
  }

  const boxLeft = (dataBlock, index) => {
    const countBlock =
      dataBlock.width > bookmarkWidth + 24
        ? Math.floor(dataBlock.width / (bookmarkWidth + 24))
        : bookmarkWidth + 24
    const blockWidth = dataBlock.width / countBlock - 24

    const ind = index + 1
    let lineCount = 0

    if (countBlock < ind) {
      lineCount = ind - Math.floor(ind / countBlock) * countBlock
      lineCount = countBlock - (countBlock - Math.abs(lineCount))
      lineCount = lineCount === 0 ? countBlock : lineCount
    } else {
      lineCount = countBlock - ind
      lineCount = countBlock - Math.abs(lineCount)
      lineCount = lineCount - 1
      return lineCount > 0 ? lineCount * (blockWidth + 24) : 0
    }

    lineCount = lineCount - 1

    return lineCount > 0 ? lineCount * (blockWidth + 24) : 0
  }

  const showBlock = (dataBlock, index) => {
    const boxsTop = boxTop(dataBlock, index)
    const boxHeight = 154 + 24

    const top = dataBlock.top

    if ((boxsTop + boxHeight) < Math.abs(top)) {
      return false
    }
    return boxsTop <= Math.abs(top) + height;
  }

  const callBackRef = useCallback(domNode => {
    if (domNode) {
      setBookmarksGroupBlock(domNode.getBoundingClientRect());
    }
  }, [useWindowSize(),
    // useScroll('baron-bookmarks-content')
  ]);


  return {
    bookmarkGroupData: bookmarksGroupData,

    bookmarkGroupBlock: bookmarksGroupBlock,
    callBackRef,
    pageHeight,
    showBlock,
    boxTop,
    boxWidth,
    boxLeft,

    isModalBookmarkAdd,
    setIsModalBookmarkAdd,

    upBookmarksGroup,
    closeBookmarksGroup,
  }
}
