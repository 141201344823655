import React, {useContext, useRef} from "react";
import useToggle from "../../../../../hooks/useToggle";
import axios from "axios";
import {BookmarksContext, saveBookmarksData} from "../../../../../contexts/bookmarks";
import {Input, isValidateNullText, Loading, Modal} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../../../config/url";
import {toast} from "sonner";
import {useNavigate} from "react-router-dom";

function ModalBookmarksGroupAdd(props) {
  const {bookmarksState, bookmarksDispatch} = useContext(BookmarksContext);
  const [isHandleSubmit, setIsHandleSubmit] = useToggle();
  const navigate = useNavigate();

  const inputTitle = useRef(null);

  const handleAdd = (event) => {
    event.preventDefault();

    // const menuScrollHeight = document.getElementById("baron-bookmarks-navigate").scrollHeight;

    const title = inputTitle.current.value;

    if (isValidateNullText(title)) {
      toast.error("Заполните все обязательные поля");
      return;
    }

    setIsHandleSubmit();

    const jsonData = JSON.stringify({
      title: title,
    });

    axios.post(
      '/api/bookmark-group/add',
      jsonData,
      {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('tokenSession'),
        },
      })
      .then((response) => {
        if (!!response.data) {
          if (!!bookmarksState.bookmarksData.groups) {
            bookmarksState.bookmarksData.groups.push(response.data);
          } else {
            bookmarksState.bookmarksData.groups = [response.data];
          }

          navigate('/my/bookmarks/group/' + response.data.uuid);

          // document.getElementById("Menu").scrollTop = menuScrollHeight;

          saveBookmarksData(bookmarksDispatch, {...bookmarksState.bookmarksData});
        }

        props.handleClose();
      })
      .catch((error) => {
        toast.error("Что то пошло не так, попробуйте чуть позже");
      })
      .finally(() => {
        setIsHandleSubmit();
      });
  }

  return (
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={event => (handleAdd(event))}>
        <div className="Form__title">
          Новая группа
        </div>

        <div className="Form__field">
          <Input autoFocus={true} reference={inputTitle} label="Название *"/>
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue"
                  type="submit"
                  style={{
                    position: "relative"
                  }}
                  disabled={isHandleSubmit ? 'disabled' : null}>
            Добавить
            <div style={{position: "absolute", right: 6, top: 6}}>
              {isHandleSubmit ? <Loading white={true}/> : null}
            </div>
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalBookmarksGroupAdd;
