import React, {useState} from "react";
import {ButtonIcon, Pagination, Search, Select, TextSearchMarker} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import ModalLinksImport from "./ModalLinksImport";

const LinksPage = (props) => {
  const {
    list,
    setList,
    count,
    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,
    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,
    inputSearchText,
    onChangeSearchText,
    searchQuery,
    setIsUpdateList,
    isUpdateList,

    isError,
    setIsError,

    openLink,
    lastVisitText,
    lastVisitColor,
    lastVisitDataTime,

    isSelect,
    selectLinks,
    setSelectLinks,
    isSelectLink,
    isSelectAllLink,
    changeSelectLink,
    changeSelectAllLink,
  } = props;

  const [isModalLinksImport, setIsModalLinksImport] = useState(false);

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Ссылки
            {!!list && (
              <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({count})
              </span>
            )}
            <span style={{width: 220, marginLeft: 16}}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>
            <div className="Button__swith">
              <div
                className={
                  "Swith" +
                  ((!!isError && " Swith_active Swith_active_red ") || "")
                }
                onClick={() => setIsError(!isError)}
              ></div>
            </div>

            {!!selectLinks && selectLinks.length > 0 && (
              <div
                style={{
                  backgroundColor: "#0061ff",
                  padding: "0 3px 0 4px",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 24,
                  marginLeft: 24,
                }}
              >
                <div
                  style={{
                    padding: "4px 6px",
                    color: "white",
                    fontSize: 14,
                    pointerEvents: "auto",
                    fontWeight: 700,
                    cursor: "default"
                  }}
                >
                  {selectLinks.length}
                </div>
                <ButtonIcon
                  style={{
                    marginBottom: 2,
                  }}
                  tooltipText={"Отменить"}
                  iconName={"custom-circle-close"}
                  iconColor={"white"}
                  size={18}
                  onClick={() => setSelectLinks([])}
                />
              </div>
            )}
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{marginLeft: 8}}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 8}}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 64,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 25,
                      title: 25,
                    },
                    {
                      value: 100,
                      title: 100,
                    },
                    {
                      value: 500,
                      title: 500,
                    },
                  ]}
                  label="Лимит"
                  onChange={(event) => setPageLimit(Number(event.target.value))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div style={{marginLeft: 8}}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}

            {/*<ButtonIcon*/}
            {/*  tooltipText={"Добавить"}*/}
            {/*  iconName={"custom-add"}*/}
            {/*  iconColor={"#0064ff"}*/}
            {/*  size={36}*/}
            {/*  style={{marginLeft: 12}}*/}
            {/*  onClick={() => setIsModalLinkAdd(true)}*/}
            {/*/>*/}
          </div>
        </div>

        <div className="Page__content">
          <ul className="List">
            {list &&
              list.map((item) => (
                <React.Fragment key={"item_" + item.uuid}>
                  <li
                    className={
                      isUnroll(item.uuid) ? "List__item active" : "List__item"
                    }
                    onClick={!!isSelect && isSelect ? () => changeSelectLink(item.uuid) : () => changeUnroll(item.uuid)}
                    style={{
                      backgroundColor: isSelectLink(selectLinks, item.uuid) ? "#0061ff25" : item.status !== "200" && "#ffab91"
                    }}
                  >
                    <div className="Item__title">
                      <div className="Element__title">
                        <div>
                          <div>
                            {item.id}{" "}|{" "}
                            {item.title ? (
                              <TextSearchMarker
                                text={item.title}
                                search={searchQuery}
                              />
                            ) : (
                              "-"
                            )}
                            <div
                              style={{
                                fontSize: 13,
                                fontWeight: 500,
                                wordBreak: "break-all"
                              }}
                            >
                              <TextSearchMarker
                                text={item.url.substring(0, 256)}
                                search={searchQuery}
                              />
                              {item.url.length > 256 && "..."}
                            </div>

                            {item.is_queue && (
                              <div style={{
                                fontSize: 10,
                                fontWeight: 400,
                                color: "#0064ff"
                              }}>
                                В очереди
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Item__settings">
                      {(!!item.status && item.status) || "-"}
                    </div>
                  </li>

                  {isUnroll(item.uuid) && (
                    <li className="List__content">
                      <div>
                        <table className="Table">
                          <tbody>
                          <tr className="Table__tr">
                            <td className="Table__td" style={{width: 162}}>
                              <div className="Td__title">Ссылка</div>
                            </td>
                            <td className="Table__td">
                              <span
                                onClick={() => openLink(item.url)}
                                style={{
                                  cursor: "pointer",
                                  color: "#2196f3",
                                  textDecoration: "underline",
                                  wordBreak: "break-all"
                                }}
                              >
                              <TextSearchMarker
                                text={item.url}
                                search={searchQuery}
                              />
                              </span>
                            </td>
                          </tr>
                          <tr className="Table__tr">
                            <td className="Table__td" style={{width: 162}}>
                              <div className="Td__title">Хост</div>
                            </td>
                            <td className="Table__td">
                              <TextSearchMarker
                                text={item.host}
                                search={searchQuery}
                              />
                            </td>
                          </tr>
                          <tr className="Table__tr">
                            <td className="Table__td" style={{width: 162}}>
                              <div className="Td__title">Адрес</div>
                            </td>
                            <td className="Table__td">
                              <span
                                onClick={() => openLink(item.href)}
                                style={{
                                  cursor: "pointer",
                                  color: "#2196f3",
                                  textDecoration: "underline",
                                  wordBreak: "break-all"
                                }}
                              >
                              <TextSearchMarker
                                text={item.href}
                                search={searchQuery}
                              />
                              </span>
                            </td>
                          </tr>
                          <tr className="Table__tr">
                            <td className="Table__td">
                              <div className="Td__title">Заголовок</div>
                            </td>
                            <td className="Table__td">
                              <TextSearchMarker
                                text={item.title}
                                search={searchQuery}
                              />
                            </td>
                          </tr>
                          <tr className="Table__tr">
                            <td className="Table__td">
                              <div className="Td__title">Описание</div>
                            </td>
                            <td className="Table__td">
                              <TextSearchMarker
                                text={item.description}
                                search={searchQuery}
                              />
                            </td>
                          </tr>
                          <tr className="Table__tr">
                            <td className="Table__td">
                              <div className="Td__title">Ключевые слова</div>
                            </td>
                            <td className="Table__td">
                              <TextSearchMarker
                                text={item.keywords}
                                search={searchQuery}
                              />
                            </td>
                          </tr>
                          <tr className="Table__tr">
                            <td className="Table__td">
                              <div className="Td__title">Иконка</div>
                            </td>
                            <td className="Table__td">
                              {(!!item.favicon && item.favicon) || "-"}
                              <br/>
                              {!!item.favicon && (
                                <img
                                  src={item.favicon}
                                  style={{
                                    height: 22,
                                    width: 22,
                                    marginRight: 12,
                                  }}
                                />
                              )}
                            </td>
                          </tr>
                          <tr className="Table__tr">
                            <td className="Table__td">
                              <div className="Td__title">Тип содержимого</div>
                            </td>
                            <td className="Table__td">
                              <TextSearchMarker
                                text={item.content_type}
                                search={searchQuery}
                              />
                            </td>
                          </tr>
                          <tr className="Table__tr">
                            <td className="Table__td">
                              <div className="Td__title">Статус ответа</div>
                            </td>
                            <td className="Table__td">
                              <TextSearchMarker
                                text={item.status}
                                search={searchQuery}
                              />
                            </td>
                          </tr>

                          <tr className="Table__tr">
                            <td className="Table__td" style={{width: 162}}>
                              <div className="Td__title">Время создания</div>
                            </td>
                            <td className="Table__td">
                              <div
                                className="Marker Marker_right"
                                style={{
                                  display: "inline-block",
                                  background: lastVisitColor(
                                    item.created_at
                                      ? new Date(item.created_at).getTime()
                                      : 0
                                  ),
                                }}
                              />
                              {lastVisitDataTime(
                                item.created_at
                                  ? new Date(item.created_at).getTime()
                                  : 0
                              )}{" "}
                              (
                              {lastVisitText(
                                item.created_at
                                  ? new Date(item.created_at).getTime()
                                  : 0
                              )}
                              )
                            </td>
                          </tr>

                          <tr className="Table__tr">
                            <td className="Table__td" style={{width: 162}}>
                              <div className="Td__title">Время обновления</div>
                            </td>
                            <td className="Table__td">
                              <div
                                className="Marker Marker_right"
                                style={{
                                  display: "inline-block",
                                  background: lastVisitColor(
                                    item.updated_at
                                      ? new Date(item.updated_at).getTime()
                                      : 0
                                  ),
                                }}
                              />
                              {lastVisitDataTime(
                                item.updated_at
                                  ? new Date(item.updated_at).getTime()
                                  : 0
                              )}{" "}
                              (
                              {lastVisitText(
                                item.updated_at
                                  ? new Date(item.updated_at).getTime()
                                  : 0
                              )}
                              )
                            </td>
                          </tr>

                          {/*<tr className="Table__tr">*/}
                          {/*  <td className="Table__td" style={{width: 162}}>*/}
                          {/*    <div className="Td__title">Снимок страницы</div>*/}
                          {/*  </td>*/}
                          {/*  <td className="Table__td">*/}
                          {/*    {item.screenshot}*/}
                          {/*    <br/>*/}
                          {/*    <img*/}
                          {/*      src={*/}
                          {/*        "https://inbookmark.org" +*/}
                          {/*        "/service/screenshot/" +*/}
                          {/*        item.screenshot*/}
                          {/*      }*/}
                          {/*      style={{*/}
                          {/*        width: 256,*/}
                          {/*      }}*/}
                          {/*    />*/}
                          {/*  </td>*/}
                          {/*</tr>*/}

                          <tr className="Table__tr">
                            <td className="Table__td" style={{width: 162}}>
                              <div className="Td__title">
                                Изображения
                              </div>
                            </td>
                            <td className="Table__td">
                              {(!!item.image && item.image) || "-"}
                              <br/>
                              {!!item.image && (
                                <img
                                  src={item.image}
                                  style={{
                                    width: 256,
                                  }}
                                />
                              )}
                            </td>
                          </tr>

                          <tr className="Table__tr">
                            <td className="Table__td" style={{width: 162}}>
                              <div className="Td__title">
                                Уникальный идентификатор
                              </div>
                            </td>
                            <td className="Table__td">{item.uuid}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="Content__footer">
                        <div className="Footer__title"></div>
                        <div className="Footer__settings"></div>
                      </div>
                    </li>
                  )}
                </React.Fragment>
              ))}
          </ul>

          {!!selectLinks && selectLinks.length > 0 && (
            <div
              style={{
                position: "absolute",
                bottom: 24,
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
                right: 48,
                zIndex: 10,
                pointerEvents: "none",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#0061ff",
                    padding: "8px 12px",
                    color: "white",
                    marginLeft: 3,
                    marginRight: 3,
                    cursor: "pointer",
                    borderRadius: 24,
                    fontSize: 15,
                    fontWeight: 700,
                    pointerEvents: "auto",
                  }}
                  onClick={() => setIsModalLinksImport(true)}
                >
                  На импорт
                </div>
              </div>
            </div>
          )}
        </div>

        {!!isModalLinksImport && (
          <ModalLinksImport
            links={selectLinks}
            successfulRequest={() => (setSelectLinks([]), setIsUpdateList(!isUpdateList))}
            handleClose={() => setIsModalLinksImport(false)}
          />
        )}
      </div>
    </ScrollBlock>
  );
};

export default LinksPage;
