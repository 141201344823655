import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import "./GroupBookmarks.scss";
import axios from "axios";
import {BookmarksContext, saveBookmarksGroup} from "../../../../../contexts/bookmarks";
import useWindowSize from "../../../../../hooks/useWindowSize";
import useToggle from "../../../../../hooks/useToggle";
import {ROOT_URL} from "../../../../../config/url";

import {useParams} from "react-router-dom";
import useScroll from "../../../../../hooks/useScroll";

function useComponentVisible(initialIsVisible) {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const componentVisibleRef = useRef(null);

    const handleClickOutside = (event) => {
        if (componentVisibleRef.current && !componentVisibleRef.current.contains(event.target)) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    });

    return { componentVisibleRef, isComponentVisible, setIsComponentVisible };
}

export const useBookmarkGroup = (props) => {
    const {width, height} = useWindowSize();

    const {bookmarksState, bookmarksDispatch} = useContext(BookmarksContext);
    const [bookmarksGroupData, setBookmarksGroupData] = useState(null);
    const [isBookmarksGroupUpdate, setIsBookmarksGroupUpdate] = useToggle();
    const [bookmarksGroupBlock, setBookmarksGroupBlock] = useState(null);

    const [isModalBookmarkAdd, setIsModalBookmarkAdd] = useState(false);
    const [isModalBookmarksGroupEdit, setIsModalBookmarksGroupEdit] = useState(false);
    const [isModalBookmarksGroupDelete, setIsModalBookmarksGroupDelete] = useState(false);

    const [isMenuSettingsPopup, setIsMenuSettingsPopup] = useState(false);
    const { componentVisibleRef, isComponentVisible, setIsComponentVisible } = useComponentVisible(true);

    let { bookmarksGroupId } = useParams();

    useEffect(() => {
        if (!isComponentVisible) {
            setIsMenuSettingsPopup(false);
        }
        return () => {setIsComponentVisible(true)};
    }, [isComponentVisible]);

    useEffect(() => {
        if (bookmarksGroupData) {
            document.title = bookmarksGroupData.title;
        }
        return () => {document.title = 'Вкладки'};
    }, [bookmarksGroupData]);


    useEffect(() => {
        // setIsLoader(true);
        axiosBookmarksGroup(bookmarksGroupId, (response) => {
            if (response.data) {
                // setBookmarksGroupData(response.data.response.data);
                saveBookmarksGroup(bookmarksDispatch, response.data);
            }
            // setIsLoader(false);
            let block = document.getElementById("Links");
            block.scrollTop = 0;
        }, null).then();
    }, [bookmarksGroupId]);

    useEffect(() => {
        if (bookmarksGroupData) {
            axiosBookmarksGroup(bookmarksGroupId, (response) => {
                if (response.data) {
                    // setBookmarksGroupData(response.data.response.data);
                    saveBookmarksGroup(bookmarksDispatch, response.data);
                }
            }, null).then();
        }
    }, [isBookmarksGroupUpdate]);

    useEffect(() => {
        setBookmarksGroupData(bookmarksState.bookmarksGroup);
    }, [bookmarksState.bookmarksGroup]);


    useEffect(() => {
        return () => {
            setBookmarksGroupData(null);
            saveBookmarksGroup(bookmarksDispatch, null);
        };
    }, []);

    async function axiosBookmarksGroup(bookmarksGroupId, callbackSuccess, callbackError) {
        axios.get(
            // 'api/links/group/' + bookmarksGroupId,
            '/api/bookmark-group/' + bookmarksGroupId,
            {
                withCredentials: false,
                baseURL: ROOT_URL,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + localStorage.getItem('tokenSession')
                }
            }).then((response) => {
            if (callbackSuccess) callbackSuccess(response);
        }).catch((err) => {
            if (callbackError) callbackError(err);
        });
    }

    const callBackRef = useCallback(domNode => {
        if (domNode) {
            setBookmarksGroupBlock(domNode.getBoundingClientRect());
        }
      }, [useWindowSize(),
      // useScroll('baron-bookmarks-content')
    ]);


    const upBookmarksGroup = () => {
        let block = document.getElementById("Links");
        block.scrollTop = 0;
    }







    // const bookmarkWidth = 246;
    const bookmarkWidth = 216;

    const pageHeight = (dataBlock) => {
        if (bookmarksGroupData && bookmarksGroupData.bookmarks) {
        const bookmarks = bookmarksGroupData.bookmarks
        const countBlock =
          dataBlock.width > bookmarkWidth + 24
            ? Math.floor(dataBlock.width / (bookmarkWidth + 24))
            : bookmarkWidth + 24

        const lineCount = Math.ceil(bookmarks.length / countBlock)

        return lineCount * (154 + 24)
        }
        return 0
      }

      const boxWidth = (dataBlock) => {
        const countBlock =
          dataBlock.width > bookmarkWidth + 24
            ? Math.floor(dataBlock.width / (bookmarkWidth + 24))
            : bookmarkWidth + 24
        return dataBlock.width / countBlock - 24
      }

      const boxTop = (dataBlock, index) => {
        const countBlock =
          dataBlock.width > bookmarkWidth + 24
            ? Math.floor(dataBlock.width / (bookmarkWidth + 24))
            : bookmarkWidth + 24

        const ind = index + 1

        let lineCount = 0

        if (countBlock < ind) {
          lineCount = Math.floor(index / countBlock)
        }

        return lineCount > 0 ? lineCount * (154 + 24) : 0
      }

      const boxLeft = (dataBlock, index) => {
        const countBlock =
          dataBlock.width > bookmarkWidth + 24
            ? Math.floor(dataBlock.width / (bookmarkWidth + 24))
            : bookmarkWidth + 24
        const blockWidth = dataBlock.width / countBlock - 24

        const ind = index + 1
        let lineCount = 0

        if (countBlock < ind) {
          lineCount = ind - Math.floor(ind / countBlock) * countBlock
          lineCount = countBlock - (countBlock - Math.abs(lineCount))
          lineCount = lineCount === 0 ? countBlock : lineCount
        } else {
          lineCount = countBlock - ind
          lineCount = countBlock - Math.abs(lineCount)
          lineCount = lineCount - 1
          return lineCount > 0 ? lineCount * (blockWidth + 24) : 0
        }

        lineCount = lineCount - 1

        return lineCount > 0 ? lineCount * (blockWidth + 24) : 0
      }

      const showBlock = (dataBlock, index) => {
        const boxsTop = boxTop(dataBlock, index)
        const boxHeight = 154 + 24
        const top = dataBlock.top

        if ((boxsTop + boxHeight) < Math.abs(top)) {
          return false
        }
        return boxsTop <= Math.abs(top) + height;
      }







    return {
      bookmarkGroupData: bookmarksGroupData,

      bookmarkGroupBlock: bookmarksGroupBlock,
      callBackRef,
      pageHeight,
      showBlock,
      boxTop,
      boxWidth,
      boxLeft,

      isModalBookmarkAdd,
      setIsModalBookmarkAdd,

      upBookmarksGroup,

      isModalBookmarksGroupEdit,
      setIsModalBookmarksGroupEdit,

      isModalBookmarksGroupDelete,
      setIsModalBookmarksGroupDelete,

      componentVisibleRef,
      isMenuSettingsPopup,
      setIsMenuSettingsPopup,
    }
}
