import React, {useContext, useState} from "react";
import {BookmarksContext} from "../../../contexts/bookmarks";
import styleBookmarks from "./Bookmarks.module.scss";
import BookmarksMenu from "./components/BookmarksMenu/BookmarksMenu";
import {Route, Routes} from "react-router-dom";
import {Loading} from "@vokymlak/kabinet-ui";
import {BookmarkGroupOther} from "./components/BookmarkGroupOther";
import {BookmarkGroup} from "./components/BookmarkGroup";

function MyBookmarksPage({myTariff}) {
  const {bookmarksState} = useContext(BookmarksContext);

  return (
    <>
      {!bookmarksState.bookmarksData && (
        <div className="Content__loader">
          <Loading/>
        </div>
      )}

      {!!bookmarksState.bookmarksData && (
        <>
          <div className={styleBookmarks._app}>
            <div className={styleBookmarks._navigate}>
              <BookmarksMenu/>
            </div>

            <div className={styleBookmarks._content}>
              {/*<Baron baronId={'baron-bookmarks-content'}>*/}
                <Routes>
                  <Route
                    path={"/other"}
                    element={<BookmarkGroupOther/>}
                  />
                  <Route
                    path={"/group/:bookmarksGroupId"}
                    element={<BookmarkGroup/>}
                  />
                </Routes>
              {/*</Baron>*/}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default MyBookmarksPage;
