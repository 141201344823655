import React, {createRef, useRef, useState} from 'react';
import axios from "axios";
import "../components/Auth.scss";
import {Link} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import {ROOT_URL} from "../../../config/url";
import {InputText} from "../components/InputText/InputText";
import {z} from "zod";
import {toast} from "sonner";
import logotype from "../components/logotype.png";
import moment from "moment";
import {ReactComponent as IconMarkEmailRead} from "../components/mark-email-read.svg";
import {getErrorMessageByKey} from "@vokymlak/kabinet-ui";

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

const getPhoneNumber = (value) => {
  if (!value) {
    value = '';
  }
  return value.replace(/\D/g, '').length > 11
    ? value.replace(/\D/g, '').substring(0, 11)
    : value.replace(/\D/g, '');
};

// Функция для форматирования номера телефона
const formatPhoneNumber = (value) => {
  if (value.length === 0 || value.length === 1) {
    return '+7 (';
  } else if (value.length === 2) {
    return '+7 (' + value.substring(1, 2);
  } else if (value.length === 3) {
    return '+7 (' + value.substring(1, 3);
  } else if (value.length === 4) {
    return '+7 (' + value.substring(1, 4);
  } else if (value.length === 5) {
    return '+7 (' + value.substring(1, 4) + ') ' + value.substring(4, 5);
  } else if (value.length === 6) {
    return '+7 (' + value.substring(1, 4) + ') ' + value.substring(4, 6);
  } else if (value.length === 7) {
    return '+7 (' + value.substring(1, 4) + ') ' + value.substring(4, 7);
  } else if (value.length === 8) {
    return (
      '+7 (' +
      value.substring(1, 4) +
      ') ' +
      value.substring(4, 7) +
      '-' +
      value.substring(7, 8)
    );
  } else if (value.length === 9) {
    return (
      '+7 (' +
      value.substring(1, 4) +
      ') ' +
      value.substring(4, 7) +
      '-' +
      value.substring(7, 9)
    );
  } else if (value.length === 10) {
    return (
      '+7 (' +
      value.substring(1, 4) +
      ') ' +
      value.substring(4, 7) +
      '-' +
      value.substring(7, 9) +
      '-' +
      value.substring(9, 10)
    );
  } else if (value.length === 11 || value.length > 11) {
    return (
      '+7 (' +
      value.substring(1, 4) +
      ') ' +
      value.substring(4, 7) +
      '-' +
      value.substring(7, 9) +
      '-' +
      value.substring(9, 11)
    );
  } else {
    return ''
  }
};

export const Registration = () => {
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);
  const [isRequest, setIsRequest] = useState(false);
  const [isPersonalDataProcessingConsented, setIsPersonalDataProcessingConsented] = useState(false);
  const [isNotPromoMailsConsented, setIsNotPromoMailsConsented] = useState(false);

  const checkboxIsPersonalDataProcessingConsented = useRef(null);
  const checkboxIsNotPromoMailsConsented = useRef(null);

  // Данные формы.
  const [formData, setFormData] = useState({
    firstName: null,
    email: null,
    phone: null,
  });

  // Данные об ошибках полей формы.
  const [formErrors, setFormErrors] = useState({
    firstName: false,
    email: false,
    phone: false,
  });

  const [recaptchaError, setRecaptchaError] = useState(false);

  const recaptchaRef = createRef();

  const changeFormData = (event) => {
    let {name, value} = event.target;

    let newValue = value

    if (name === 'phone') {
      newValue = getPhoneNumber(newValue)
    }

    if (newValue === '') {
      newValue = null
    }

    setFormData(prevData => ({
      ...prevData,
      [name]: newValue
    }));

    setFormErrors(prevError => ({
      ...prevError,
      [name]: false
    }));
  };

  const handleRegister = async () => {
    const FormDataSchema = z
      .object({
        firstName: z.string(),
        email: z.string().email(),
        // phone: z.string().min(11).max(11),
      });

    try {
      if (formData.firstName) {
        formData.firstName.trim()
      }
      FormDataSchema.parse({...formData});
    } catch (error) {
      if (error instanceof z.ZodError) {
        // Обработка ошибок.
        error.issues.forEach(err => {
          err.path.forEach(name => {
            setFormErrors(prevError => ({
              ...prevError,
              [name]: true
            }));
          })
        })
      }

      // toast.error(`Пожалуйста, заполните корректно поле электронной почты`);
      toast.error(`Пожалуйста, заполните корректно все поля`);
      return;
    }

    if (recaptchaError) {
      await onRecaptchaResolved();
    } else {
      recaptchaRef.current.execute();
    }
  }

  async function onRecaptchaResolved() {
    const jsonData = JSON.stringify({
      first_name: formData.firstName,
      email: formData.email.toLowerCase(),
      // phone: formData.phone,
      is_personal_data_processing_consented: isPersonalDataProcessingConsented,
      is_not_promo_mails_consented: isNotPromoMailsConsented
    });

    setIsRequest(true);

    axios.post(
      'api/auth/register',
      jsonData,
      {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(() => {
        setIsRegisterSuccess(true);
      })
      .catch(error => {
        console.error(error);
        if (error.response.status === 488) {
          toast.error("Номером телефона уже занят другим пользователем");
        } else if (error.response.status === 477) {
          toast.error("Аккаунт с таким email уже существует");
        } else {
          toast.error('Что то пошло не так, попробуйте зарегистрироваться чуть позже');
        }
      })
      .finally(() => {
        setIsRequest(false);
      });
  }

  const changeIsPersonalDataProcessingConsented = () => {
    setIsPersonalDataProcessingConsented(checkboxIsPersonalDataProcessingConsented.current.checked);
  };

  const changeIsNotPromoMailsConsented = () => {
    setIsNotPromoMailsConsented(checkboxIsNotPromoMailsConsented.current.checked);
  };

  return (
    <div className="Auth"
         onContextMenu={(event) => event.preventDefault()}
    >
      {!isRegisterSuccess && (
        <>
          <div className="Form show">
            <div className="Form__logotype">
              {/*<img src={logotype} alt={"LEENKO"} height={23}/>*/}
              <div style={{
                fontSize: 15,
                fontWeight: 400,
                marginTop: 6,
                marginBottom: 24
              }}>
                Личный кабинет
              </div>
            </div>

            <div className="Form__title">
              Регистрация
              <div className="Text__signature">
                Зарегистрируйтесь, чтобы начать работу с сервисом
              </div>
            </div>

            <div className="Form__field">
              <InputText
                label={"Ваше имя"}
                isRequired={true}
                name={'firstName'}
                type={'text'}
                value={formData.firstName}
                onChange={changeFormData}
                isError={formErrors['firstName']}
              />
            </div>

            <div className="Form__field">
              <InputText
                label={"Электронная почта"}
                isRequired={true}
                name={'email'}
                type={'email'}
                value={formData.email}
                onChange={changeFormData}
                isError={formErrors['email']}
              />
            </div>

            {/*<div className="Form__field">*/}
            {/*  <InputText*/}
            {/*    label={"Номер телефона"}*/}
            {/*    isRequired={true}*/}
            {/*    name="phone"*/}
            {/*    value={formatPhoneNumber(formData.phone || "")}*/}
            {/*    onChange={changeFormData}*/}
            {/*    isError={formErrors['phone']}*/}
            {/*  />*/}
            {/*</div>*/}

            <div className="Form__field" style={{
              marginBottom: 16
            }}>
            <span style={{fontSize: 13, fontWeight: 400, opacity: .5}}>
              Нажимая кнопку «Зарегистрироваться»:
            </span>
            </div>

            <div className="Form__field">
              <label style={{
                display: "flex"
              }}>
                <InputText
                  reference={checkboxIsPersonalDataProcessingConsented}
                  type={'checkbox'}
                  onChange={changeIsPersonalDataProcessingConsented}
                />
                <span style={{marginLeft: 6, fontSize: 15, fontWeight: 400, cursor: "pointer"}}>
                Я даю согласие на обработку <br/>моей персональной информации <br/>на условиях, определенных
                <Link className="Action__link Action__link_blue" to={"https://leenko.ru/privacy"} target={"_blank"}>Политикой конфиденциальности</Link>
              </span>
              </label>
            </div>

            <div className="Form__field Form__field_last">
              <label style={{
                display: "flex"
              }}>
                <InputText
                  reference={checkboxIsNotPromoMailsConsented}
                  type={'checkbox'}
                  onChange={changeIsNotPromoMailsConsented}
                />
                <span style={{marginLeft: 6, fontSize: 15, fontWeight: 400, cursor: "pointer"}}>
                Я не хочу получать рекламу и другие предложения данного сервиса
              </span>
              </label>
            </div>

            <div className="Form__actions Form__actions_center">
              <button
                className="Action__button Action__button_all Action__button_login"
                style={{
                  opacity: (isRequest || !isPersonalDataProcessingConsented) && 0.5,
                  cursor: (isRequest || !isPersonalDataProcessingConsented) && "default"
                }}
                onClick={(isRequest || !isPersonalDataProcessingConsented) ? () => null : () => handleRegister()}
              >
                Зарегистрироваться
              </button>
            </div>

            <div className="Form__links">
              <span style={{marginRight: 6}}>Уже есть учётная запись?</span>
              <Link className="Action__link Action__link_blue" to={"/login"}>Войти</Link>
            </div>
          </div>

          <div className="Form__links">
            <span style={{opacity: .75}}>
              ©{" "}2023-{moment().format("YYYY")}{" "}
              <Link className="Action__link" to={"https://leenko.ru"}>LEENKO</Link>
            </span>
          </div>
        </>
      )}

      {isRegisterSuccess && (
        <div className="Form show" style={{
          backgroundColor: "inherit",
          boxShadow: "none",
          width: 426
        }}>
          <div className="Form__title" style={{
            textAlign: "center",
            opacity: .33
          }}>
            Регистрация
          </div>

          <div className="Form__field" style={{
            display: "flex",
            justifyContent: "center"
          }}>
            <IconMarkEmailRead height={128} fill={"#0064ff"}/>
          </div>

          <div className="Form__field" style={{
            display: "flex",
            justifyContent: "center"
          }}>
            <div style={{
              textAlign: "center"
            }}>
              <div style={{
                borderRadius: 48,
                padding: "8px 16px",
                backgroundColor: "#dbdbdb",
                display: "inline-block"
              }}>
                {formData.email}
              </div>
            </div>
          </div>

          <div className="Form__field" style={{
            display: "flex",
            justifyContent: "center"
          }}>
            <div style={{
              textAlign: "center"
            }}>
              Мы отправили данные для входа на указанный вами адрес электронной почты.<br/>
              На всякий случай, пожалуйста, проверьте папку "Спам" в вашем почтовом ящике, если письмо не пришло в вашу основную входящую.
            </div>
          </div>

          <div className="Form__links">
            <Link className="Action__link Action__link_blue" to={"/login"}>К входу</Link>
          </div>
        </div>
      )}

      <ReCAPTCHA
        ref={recaptchaRef}
        locale="ru"
        size="invisible"
        sitekey={RECAPTCHA_KEY}
        onChange={onRecaptchaResolved}
        onErrored={() => setRecaptchaError(true)}
      />
    </div>
  );
}
