import React, {useContext, useRef} from "react";
import axios from "axios";
import {bookmarkBufferView,} from "../../../../../contexts/bookmarks/bookmarksAction";
import {BookmarksContext, saveBookmarksGroup} from "../../../../../contexts/bookmarks";
import useToggle from "../../../../../hooks/useToggle";
import {ROOT_URL} from "../../../../../config/url";
import {Input, isValidateNullText, Loading, Modal} from "@vokymlak/kabinet-ui";
import {toast} from "sonner";

function ModalBookmarkOtherAdd(props) {
  const {bookmarksState, bookmarksDispatch} = useContext(BookmarksContext);

  const [isHandleSubmit, setIsHandleSubmit] = useToggle();

  const inputLink = useRef(null);

  function addBookmarkData(bookmark) {
    const bookmarksGroup = bookmarksState.bookmarksGroup;
    bookmarksGroup.count += 1;
    if (!!bookmarksGroup.bookmarks) {
      bookmarksGroup.bookmarks = [bookmark, ...bookmarksGroup.bookmarks];
    } else {
      bookmarksGroup.bookmarks = [bookmark];
    }

    saveBookmarksGroup(bookmarksDispatch, bookmarksGroup);
  }

  function linkBufferView(isLinkBuffer) {
    bookmarkBufferView(bookmarksDispatch, isLinkBuffer).then();
  }

  const handleAdd = (event) => {
    event.preventDefault();

    const linkUrl = inputLink.current.value;

    if (isValidateNullText(linkUrl)) {
      toast.error("Укажите ссылку");
      return;
    }

    {
      let parser;
      try {
        parser = new URL(linkUrl);
      } catch (error) {
        if (error instanceof TypeError) {
          // Указанный текст не является валидным URL
          toast.error("Указанный текст не является ссылкой");
          return;
        } else {
          // Другая ошибка
          throw error;
        }
      }

      // Проверяем, что URL является корректным
      if (parser.protocol && parser.host) {
        // URL корректен
      } else {
        // URL некорректен
        toast.error("Указанный текст не является ссылкой");
        return;
      }
    }

    setIsHandleSubmit();

    const jsonData = JSON.stringify({
      link_url: encodeURI(linkUrl),
    })

    axios.post(
      '/api/bookmark-group/other/bookmark/add',
      jsonData,
      {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('tokenSession'),
        },
      })
      .then((response) => {
        if (!!response.data) {
          linkBufferView(false);
          addBookmarkData(response.data);
        }

        props.handleClose();
      })
      .catch((error) => {
        toast.error("Что то пошло не так, попробуйте чуть позже");
      })
      .finally(() => {
        setIsHandleSubmit();
      });
  }

  return (
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={event => (handleAdd(event))}>
        <div className="Form__title">
          Новая вкладка
        </div>

        <div className="Form__field">
          <Input autoFocus={true} reference={inputLink} label="Ссылка *"/>
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue"
                  type="submit"
                  style={{
                    position: "relative"
                  }}
                  disabled={isHandleSubmit ? 'disabled' : null}>
            Добавить
            <div style={{position: "absolute", right: 6, top: 6}}>
              {isHandleSubmit ? <Loading white={true}/> : null}
            </div>
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalBookmarkOtherAdd;
