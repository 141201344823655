import { useEffect, useRef, useState } from "react";
import { ROOT_URL } from "../../../config/url";
import {useAction, useList} from "@vokymlak/kabinet-ui";
import useUnroll from "../../../hooks/useUnroll";
import {useCtrlKey} from "../../../hooks/useCtrlKey";

export default function useLinks() {
  const [isError, setIsError] = useState(false);

  const getParams = () => {
    let params = null;
    if (isError) {
      params = {
        ...params,
        error: isError,
      };
    }
    return params;
  };

  const getUrlParams = () => {
    return {
      ...getParams(),
    };
  };

  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
    params: [_paramsLinks, setParamsLinks],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
  } = useList({
    apiUrl: "/api/links",
    rootUrl:ROOT_URL,
    isPagination: true,
    paginationLimit: 100,
    isSearch: true
  });

  useEffect(() => {
    setParamsLinks({ ...getUrlParams() });
  }, [
    isError,
  ]);

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const inputSearchText = useRef(null);
  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);

  const { setAction } = useAction(["links"], () => updateList());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, "links"]);
    } else {
      setAction(["links"]);
    }
  }, [list]);

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  function onChangeSearchText() {
    setSearchQuery(inputSearchText.current.value);
  }

  const openLink = (url) => {
    // Открываем ссылку в новом окне браузера.
    // noopener,noreferrer,resizable,scrollbars,status (доп. параметры).
    const newWindow = window.open(url, "_blank", "noopener, noreferrer");
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const lastVisitText = (time) => {
    if (Number(time) > 0) {
      const allTime =
        (parseInt(String(new Date().getTime())) - 18000000 - time) / 1000;
      if (allTime < 180) {
        return "сейчас";
      } else if (allTime < 86400) {
        return "сегодня";
      } else if (allTime < 86400 * 2) {
        return "вчера";
      } else if (allTime < 86400 * 7) {
        return "на неделе";
      } else if (allTime < 86400 * 14) {
        return "неделю назад";
      } else if (allTime < 86400 * 21) {
        return "две недели";
      } else if (allTime < 86400 * 28) {
        return "три недели";
      } else if (allTime < 86400 * (30 * 2 + 1)) {
        return "месяц назад";
      } else if (allTime < 86400 * (30 * 3 + 2)) {
        return "два месяца";
      } else if (allTime < 86400 * (30 * 4 + 2)) {
        return "три месяца";
      } else if (allTime < 86400 * (30 * 5 + 3)) {
        return "четыре месяца";
      } else if (allTime < 86400 * (30 * 6 + 3)) {
        return "пять месяцев";
      } else if (allTime < 86400 * 365) {
        return "полгода";
      } else if (allTime < 86400 * 365 * 2) {
        return "год";
      } else {
        return "давно";
      }
    } else {
      return "никогда";
    }
  };

  const lastVisitColor = (time) => {
    if (Number(time) > 0) {
      const allTime =
        (parseInt(String(new Date().getTime())) - 18000000 - time) / 1000;
      if (allTime < 180) {
        return "#4caf50";
      } else if (allTime < 86400) {
        return "#4caf50";
      } else if (allTime < 86400 * 2) {
        return "#4caf50";
      } else if (allTime < 86400 * 7) {
        return "#8bc34a";
      } else if (allTime < 86400 * 14) {
        return "#8bc34a";
      } else if (allTime < 86400 * 21) {
        return "#cddc39";
      } else if (allTime < 86400 * 28) {
        return "#cddc39";
      } else if (allTime < 86400 * (30 * 2 + 1)) {
        return "#ffc107";
      } else if (allTime < 86400 * (30 * 3 + 2)) {
        return "#ffc107";
      } else if (allTime < 86400 * (30 * 4 + 2)) {
        return "#ff9800";
      } else if (allTime < 86400 * (30 * 5 + 3)) {
        return "#ff9800";
      } else if (allTime < 86400 * (30 * 6 + 3)) {
        return "#ff9800";
      } else if (allTime < 86400 * 365) {
        return "#ff5722";
      } else if (allTime < 86400 * 365 * 2) {
        return "#ff5722";
      } else {
        return "#ff5722";
      }
    } else {
      return "#9e9e9e";
    }
  };

  const lastVisitDataTime = (time) => {
    if (Number(time) > 0) {
      let date = new Date(time - 18000000);
      let day = "0" + date.getDate();
      let month = "0" + (date.getMonth() + 1);
      let year = String(date.getFullYear());
      let hours = "0" + date.getHours();
      let minutes = "0" + date.getMinutes();
      let seconds = "0" + date.getSeconds();
      let formattedData =
        day.substr(-2) + "." + month.substr(-2) + "." + year.substr(-2);
      let formattedTime =
        hours.substr(-2) + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);

      return formattedData + " " + formattedTime;
    }
  };

  const [isSelect, setIsSelect] = useState(false);
  useCtrlKey(() => {
    setIsSelect(true)
  }, () => {
    setIsSelect(false)
  });
  const [selectLinks, setSelectLinks] = useState([]);

  const isSelectLink = (selectList, uuid) => {
    let isTrue = false
    selectList.forEach(u => {
      if (u === uuid) {
        isTrue = true;
      }
    });
    return isTrue
  }

  const isSelectAllLink = (list, selectList) => {
    return !!list && !!selectList && selectList.length === list.length
  }

  const changeSelectLink = (uuid) => {
    let isNew = true;
    let index = 0;

    selectLinks.forEach((u, i) => {
      if (u === uuid) {
        isNew = false;
        index = i;
      }
    });

    if (isNew) {
      setSelectLinks([...selectLinks, uuid]);
    } else {
      selectLinks.splice(index, 1);
      setSelectLinks([...selectLinks]);
    }
  };

  const changeSelectAllLink = (list, selectList) => {
    const selectLinks = []

    if (!!list && !!selectList && selectList.length !== list.length) {
      list.forEach(item => {
        selectLinks.push(item.uuid)
      });
    }

    setSelectLinks(selectLinks);
  };

  return {
    list,
    setList,
    count,
    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,
    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,
    inputSearchText,
    onChangeSearchText,
    searchQuery,
    setIsUpdateList,
    isUpdateList,

    isError,
    setIsError,

    openLink,
    lastVisitText,
    lastVisitColor,
    lastVisitDataTime,

    isSelect,
    selectLinks,
    setSelectLinks,
    isSelectLink,
    isSelectAllLink,
    changeSelectLink,
    changeSelectAllLink,
  };
}
