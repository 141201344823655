import React from 'react'
import LinksPage from './LinksPage'
import useLinks from './useLinks'

function Links() {
  return (
    <LinksPage {...useLinks()} />
  )
}

export default Links