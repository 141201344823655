export const BOOKMARKS_REQUEST = 'bookmarks_request';
export const BOOKMARKS_REQUEST_SUCCESS = 'bookmarks_request_success';
export const BOOKMARKS_REQUEST_ERROR = 'bookmarks_request_error';
export const BOOKMARKS_GROUP = 'bookmarks_group';
export const LINKS_DELETED = 'links_deleted';
export const SELECT_BOOKMARK = 'select_bookmark';
export const BOOKMARK_DRAGGABLE = 'bookmark_draggable';
export const BOOKMARK_DRAGGABLE_SHOW = 'bookmark_draggable_show';
export const CHECKING_BUFFER = 'checking_buffer';
export const BOOKMARK_BUFFER = 'bookmark_buffer';
export const BOOKMARK_MODAL = 'bookmark_modal';
