import React, {useContext} from "react";
import useToggle from "../../../../../hooks/useToggle";
import axios from "axios";
import {ROOT_URL} from "../../../../../config/url";
import {BookmarksContext, saveBookmarksData} from "../../../../../contexts/bookmarks";
import {Loading, Modal, useObject} from "@vokymlak/kabinet-ui";
import {toast} from "sonner";
import {useNavigate, useParams} from "react-router-dom";

function ModalBookmarksGroupDelete(props) {
  const {bookmarksState, bookmarksDispatch} = useContext(BookmarksContext);
  const [isHandleSubmit, setIsHandleSubmit] = useToggle();
  const {bookmarksGroupId} = useParams();
  const navigate = useNavigate();

  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/bookmark-group/" + props.bookmarkGroupData.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const handleDelete = (event) => {
    event.preventDefault();
    // const menuScrollTop = document.getElementById("Menu").scrollTop;

    setIsHandleSubmit();

    axios.delete(
      'api/bookmark-group/' + props.bookmarkGroupData.uuid + '/delete',
      {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('tokenSession')
        }
      }).then(() => {
      bookmarksState.bookmarksData.groups.forEach((group, index) => {
        if (group.uuid === bookmarksGroupId) {
          bookmarksState.bookmarksData.groups.splice(Number(index), 1);
        }
      });

      saveBookmarksData(bookmarksDispatch, {...bookmarksState.bookmarksData});

      navigate('/my/bookmarks');

      // document.getElementById("Menu").scrollTop = menuScrollTop;

      props.handleClose();

    })
      .catch((error) => {
        toast.error("Что то пошло не так, попробуйте чуть позже");
      })
      .finally(() => {
        setIsHandleSubmit();
      });
  }

  return (
    !!object &&
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={event => (handleDelete(event))}>
        <div
          className="Form__title"
          style={{fontSize: 19, fontWeight: 700}}
        >
          {(!!object.title && object.title) || "-"}
          <div className="Text__signature">Удалить группу вкладок</div>
        </div>

        <div className="Form__field">
          <div className="Content__box">
            <div className="Box__text Box__text_center" style={{fontSize: 16}}>
              Все закладки находящиеся в группе, будут удалены.
              <br/>
              Вы уверена что хотите продолжить?
            </div>
          </div>
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_red"
                  type="submit"
                  style={{
                    position: "relative"
                  }}
                  disabled={isHandleSubmit ? 'disabled' : null}>
            Продолжить
            <div style={{position: "absolute", right: 6, top: 6}}>
              {isHandleSubmit ? <Loading white={true}/> : null}
            </div>
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalBookmarksGroupDelete;
