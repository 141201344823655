import React from 'react'
import UserView from "./UserView";
import useUser from "./useUser";

function User({user}) {
  return (
    <UserView {...useUser(user)} />
  )
}

export default User