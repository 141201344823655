import React from "react";
import style from "./User.module.scss";

const UserView = (props) => {
  const {
    bookmarkGroups,
    bookmarks,
      user,
  } = props;

  return (!!bookmarkGroups && !!bookmarks &&
      <>
      <div><img
          className={style._avatar__image}
          src={user.avatar_image}
          width="84"
          height="84"
          alt={""}
      /></div>
  <div style={{
      display: "flex",
      alignItems: "center",
      flexWrap: "nowrap",
      justifyContent: "flex-start",
      flexDirection: "row",
  }}>Группы вкладок: {bookmarkGroups.count} | Вкладки: {bookmarks.count} | Телеграм бот: {user.is_confirm_telegram && (
      <div className={style._link__true} />
  )} </div>
      </>
  );
};

export default UserView;
