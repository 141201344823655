import {useObject} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../../config/url";

export default function useUser(user) {
  const {
    object: [bookmarkGroups],
  } = useObject({
    apiUrl: "/api/user/"+user.id + "/bookmark-groups/count",
    rootUrl: ROOT_URL
  });

  const {
    object: [bookmarks],
  } = useObject({
    apiUrl: "/api/user/"+user.id + "/bookmarks/count",
    rootUrl: ROOT_URL
  });

  return {
    bookmarkGroups,
    bookmarks,
    user,
  };
}
