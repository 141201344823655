import { ROOT_URL } from "../../../config/url";
import useUnroll from "../../../hooks/useUnroll";
import {useList} from "@vokymlak/kabinet-ui";

export default function useUsers() {
  const {
    list: [list],
    update: [isUpdateList, setIsUpdateList],
  } = useList("/api/users", ROOT_URL);

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const lastVisitText = (time) => {
    if (Number(time) > 0) {
      const allTime =
        (parseInt(String(new Date().getTime())) - 18000000 - time) / 1000;
      if (allTime < 180) {
        return "сейчас";
      } else if (allTime < 86400) {
        return "сегодня";
      } else if (allTime < 86400 * 2) {
        return "вчера";
      } else if (allTime < 86400 * 7) {
        return "на неделе";
      } else if (allTime < 86400 * 14) {
        return "неделю назад";
      } else if (allTime < 86400 * 21) {
        return "две недели";
      } else if (allTime < 86400 * 28) {
        return "три недели";
      } else if (allTime < 86400 * (30 * 2 + 1)) {
        return "месяц назад";
      } else if (allTime < 86400 * (30 * 3 + 2)) {
        return "два месяца";
      } else if (allTime < 86400 * (30 * 4 + 2)) {
        return "три месяца";
      } else if (allTime < 86400 * (30 * 5 + 3)) {
        return "четыре месяца";
      } else if (allTime < 86400 * (30 * 6 + 3)) {
        return "пять месяцев";
      } else if (allTime < 86400 * 365) {
        return "полгода";
      } else if (allTime < 86400 * 365 * 2) {
        return "год";
      } else {
        return "давно";
      }
    } else {
      return "никогда";
    }
  };

  const lastVisitColor = (time) => {
    if (Number(time) > 0) {
      const allTime =
        (parseInt(String(new Date().getTime())) - 18000000 - time) / 1000;
      if (allTime < 180) {
        return "#4caf50";
      } else if (allTime < 86400) {
        return "#4caf50";
      } else if (allTime < 86400 * 2) {
        return "#4caf50";
      } else if (allTime < 86400 * 7) {
        return "#8bc34a";
      } else if (allTime < 86400 * 14) {
        return "#8bc34a";
      } else if (allTime < 86400 * 21) {
        return "#cddc39";
      } else if (allTime < 86400 * 28) {
        return "#cddc39";
      } else if (allTime < 86400 * (30 * 2 + 1)) {
        return "#ffc107";
      } else if (allTime < 86400 * (30 * 3 + 2)) {
        return "#ffc107";
      } else if (allTime < 86400 * (30 * 4 + 2)) {
        return "#ff9800";
      } else if (allTime < 86400 * (30 * 5 + 3)) {
        return "#ff9800";
      } else if (allTime < 86400 * (30 * 6 + 3)) {
        return "#ff9800";
      } else if (allTime < 86400 * 365) {
        return "#ff5722";
      } else if (allTime < 86400 * 365 * 2) {
        return "#ff5722";
      } else {
        return "#ff5722";
      }
    } else {
      return "#9e9e9e";
    }
  };

  const lastVisitDataTime = (time) => {
    if (Number(time) > 0) {
      let date = new Date(time - 18000000);
      let day = "0" + date.getDate();
      let month = "0" + (date.getMonth() + 1);
      let year = String(date.getFullYear());
      let hours = "0" + date.getHours();
      let minutes = "0" + date.getMinutes();
      let formattedData =
        day.substr(-2) + "." + month.substr(-2) + "." + year.substr(-2);
      let formattedTime = hours.substr(-2) + ":" + minutes.substr(-2);

      return formattedTime + " " + formattedData;
    }
  };

  return {
    list,
    isUpdateList,
    setIsUpdateList,
    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,
    lastVisitText,
    lastVisitColor,
    lastVisitDataTime,
  };
}
