export function hostName(url) {
    let parser = new URL(url);

    return parser.hostname.replace('www.', '');
}

export function faviconUrl(url, favicon) {

    if ((favicon.indexOf('http') > -1) ||
        (favicon.indexOf('//') > -1) ||
        (favicon.indexOf('data:image') > -1)) {

        return favicon;
    }

    else {

        let parser = new URL(url);

        return parser.protocol + '//' + parser.host + favicon;
    }
}

export function linkImage(url) {
    if (url) {
        if ((url.indexOf('http') > -1)) {
            try {
                let parser = new URL(url);
                return !!parser.pathname;
            }

            catch (e) {
                return false;
            }
        }

        else {
            return false;
        }
    }

    else {
        return false;
    }
}

export function linkTitle(title) {
    return title.replace(/&lt;/g,"<")
        .replace(/&gt;/g,">")
        .replace(/&quot;/g, '"')
        .replace(/=/g, "=")
        .replace(/&trade;/g, "™")
        .replace(/&reg;/g, "®")
        .replace(/&#8211;/g, "–")
        .replace(/&#8212;/g, "—")
        .replace(/&#38;/g, "&")
        .replace(/&amp;/g, "&")
        .replace(/&#39;/g, "'")
        .replace(/&#039;/g, "'")
        .replace(/&apos;/g, "'")
        .replace(/&#160;/g, " ")
        .replace(/&nbsp;/g, " ");
}

    /* Поиск image по meta tags */
    export function searchImageMetaTags(metaTags) {
        if (metaTags) {
            if (metaTags['og:image'] &&
                metaTags['og:image'].length === 1) {
                return formatImageSource(metaTags['og:image'][0]);
            }
            if (metaTags['twitter:image'] &&
                metaTags['twitter:image'].length === 1) {
                return formatImageSource(metaTags['twitter:image'][0]);
            }
            return null;
        }
        return null;
    }

    /* Обработка ссылки на image */
    export function formatImageSource(image) {
       if (image && (
            (image.indexOf('.png') > -1) ||
            (image.indexOf('.jpg') > -1) ||
            (image.indexOf('.jpeg') > -1) ||
            (image.indexOf('.gif') > -1) ||
            (image.indexOf('.webp') > -1)
        )) {
           return image;
       }

       return null;
    }

    

    export function dndArray(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            let k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    }

    export function inputDateMonthYear(time) {
        const date = new Date(time * 1000);
    
        let dd = date.getDate();
        if (dd < 10) dd = '0' + dd;
    
        let mm = date.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;
    
        let yyyy = date.getFullYear();
        if (yyyy < 10) yyyy = '0' + yyyy;
    
        return yyyy + '-' + mm + '-' + dd;
    }

    export function getTimeHourMinSec(time) {
        const date = new Date(time * 1000);
    
        let hour = date.getHours();
        if (hour < 10) hour = '0' + hour;
    
        let min = date.getMinutes();
        if (min < 10) min = '0' + min;
    
        let sec = date.getSeconds();
        if (sec < 10) sec = '0' + sec;
    
        return hour + ':' + min + ':' + sec;
    }

    export function getDateMonthYear(time) {
        const date = new Date(time * 1000);
    
        let dd = date.getDate();
        if (dd < 10) dd = '0' + dd;
    
        let mm = date.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;
    
        let yyyy = date.getFullYear();
        if (yyyy < 10) yyyy = '0' + yyyy;
    
        return dd + '.' + mm + '.' + yyyy;
    }

    export function fileSizeView(length) {
        if (Number(length) > 1024) {
            let i = 0, type = ['б', 'Кб', 'Мб', 'Гб', 'Тб', 'Пб'];
            while ((length / 1000 | 0) && i < type.length - 1) {
                length /= 1024;
                i++;
            }
            return length.toFixed(2) + ' ' + type[i];
        }
    
        else {
            return length + ' байт';
        }
    }

export function deletedAtDash(deletedAt, countDay, weightCount) {
    const time = new Date(deletedAt).getTime();
    const allTime = (new Date().getTime() - time) / 1000;
    const countDayTime = countDay * 60 * 60 * 24;
    const weightCountDay = countDayTime / weightCount;
    if (allTime < countDayTime) {
        return Math.round((countDayTime - allTime) / weightCountDay);
    }

    else {
        return 0
    }
}