import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {Accounts} from "./ScreenAccounts/Accounts";
import {
  ROLE_ADMINISTRATOR,
  ROLE_CLIENT,
  ROLE_DIRECTOR,
  ROLE_EMPLOYEE,
} from "../config/constants";
import { Clients } from "./ScreenAccounts/Clients";
import { Employees } from "./ScreenAccounts/Employees";
import {
  useProfile,
  isProfileRoleTypeTags,
} from "@vokymlak/kabinet-ui";
import {TariffsSetting} from "./ScreenTariffs/TariffsSetting";
import {MyTariff} from "./ScreenMy/MyTariff";
import {TariffsSelling} from "./ScreenTariffs/TariffsSelling";
import {TariffsPromoCodes} from "./ScreenTariffs/TariffsCodes";
import {LinkParser} from "./ScreenParser/LinkParser";
import {MyBookmarks} from "./ScreenMy/MyBookmarks";
import {BookmarksProvider} from "../contexts/bookmarks";
import {Links} from "./ScreenData/Links";
import {Users} from "./ScreenData/Users";

export const Routing = () => {
  const { profile } = useProfile();

  return (
    !!profile && (
      <Routes>

        {isProfileRoleTypeTags(profile, [
          ROLE_CLIENT,
        ]) && (
          <>
            <Route path="/my/tariff" element={<MyTariff />} />

            {/*<Route path="/my/bookmarks" element={<MyBookmarks />} />*/}
            <Route
              path={"/my/bookmarks/*"}
              element={
                <BookmarksProvider>
                  <MyBookmarks />
                </BookmarksProvider>
              }/>
          </>
        )}

        {isProfileRoleTypeTags(profile, [
          ROLE_EMPLOYEE,
        ]) && (
          <>
            <Route path="/employees" element={<Employees />} />
            <Route path="/clients" element={<Clients />} />

            <Route
              path="/tariffs-selling"
              element={<TariffsSelling />}
            />
          </>
        )}

        {isProfileRoleTypeTags(profile, [
          ROLE_ADMINISTRATOR,
          ROLE_DIRECTOR,
        ]) && (
          <>
            <Route path="/employees" element={<Employees />} />
            <Route path="/clients" element={<Clients />} />

            <Route path="/parser-link" element={<LinkParser />} />

            <Route
              path="/tariffs-selling"
              element={<TariffsSelling />}
            />

            <Route
              path="/tariffs-setting"
              element={<TariffsSetting />}
            />

            <Route
              path="/tariffs-codes"
              element={<TariffsPromoCodes />}
            />

            <Route path="/users" element={<Users />} />
            <Route path="/links" element={<Links />} />
          </>
        )}

        {isProfileRoleTypeTags(profile, [ROLE_ADMINISTRATOR]) && (
          <>
            <Route path="/accounts" element={<Accounts />} />
          </>
        )}

        {isProfileRoleTypeTags(profile, [
          ROLE_CLIENT,
        ]) && (
          <Route
            exact
            path="/"
            element={<Navigate to="/my/bookmarks" replace />}
          />
        )}

        <Route path="/login" element={<Navigate to="/" />} />
        <Route path="/registration" element={<Navigate to="/" />} />
        <Route path="/restore" element={<Navigate to="/" />} />
      </Routes>
    )
  );
};
